import { Injectable } from '@angular/core';

@Injectable()
export class NamesTranslateService {
	constructor() {}

	execute(object: any) {
		const lang = JSON.parse(localStorage.getItem('lang') || '{lang:"en"}');
		object.names = object.names.map(n => ({
			id: n.id,
			name: n.name,
			language: n.language.code ? n.language.code.toLowerCase() : n.language,
			languageName: n.language ? n.language.name : n.languageName
		}));
		if ( object.names.find(name => name.language === lang.lang) ) {
			object.indixeName = object.names.map(name => name.language).indexOf(lang.lang);
			object.lang = object.names.find(name => name.language === lang.lang).language;
			object.traslate = true;
		}else{
			object.indixeName = 0;
			object.lang = object.names.length > 0 ? object.names[0].language : 'en';
			object.traslate = false;
		}
		return object;
	}

	otherLanguages(object: any, languages: any) {
		let ier = object.names.length+1;
		return languages.filter(l => !object.names.find(n => n.language === l.code.toLowerCase()) ? true : false).map(lg => ({
			id: ier,
          	name: "",
          	code: lg.code.toLowerCase(),
          	languageName: lg.name
		}) || ++ier);
	}

}
