import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { MyComponent } from './component';
import { ComponentLoadingModule } from "../../../components/loading/module";
import { DirectivesModule } from "../../../components/directives/module";

import { RequestsManagerService } from "../../service";

@NgModule({
    declarations: [
        MyComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TooltipModule,
        DirectivesModule,
        ComponentLoadingModule,
        ReactiveFormsModule
    ],
    exports: [
        MyComponent
    ],
    providers: [RequestsManagerService]
})
export class RequestsPreviewUpdatedFinishedReturnedModule { }
