import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GearIconService } from './service';

@Component({
  selector: 'app-gear-icon',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class GearIconComponent  {

  @Input() state: {
    features : {
    count:number
    }
  };  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: GearIconService,
    
  ) { }

 

  goFetures(){
    this.router.navigate(['../settings/features'])
  }

}
