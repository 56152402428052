<div *ngIf="isVisible" class="margindefault" [ngClass]="{'miminalStyle': params.minimal}">
    <div class={{params.style?.class}} [ngStyle]="{'padding':'0px !important'}"
        [ngClass]="{'paddingdefault': params.minimal}">
        <div class="conttitle">
            <div class="divbtnback">
                <mat-icon *ngIf="isVisibleGoBackButton()" (click)="goBackUrl()" class="goback left112">arrow_back_ios
                </mat-icon>
            </div>
            <div class="">
                <div class="divtexttitle">
                    <span class="newTitle font16 semiblod"> {{ params.title?.value | translate }} </span>
                </div>
                <div class="divbtnadd">
                    <button *ngIf="params.addButton && !params.minimal"
                        class="btn-floating waves-effect waves-light addbtn"
                        tooltip="{{ params.addButton.title | translate }}" container="body" placement="right"
                        [routerLink]="[params.addButton.url]" [queryParams]="params.addButton.params">
                        <i class="material-icons">add</i>
                    </button>
                </div>
                <div class="right divprogess" *ngIf="params.progressBar && params.minimal">
                    <span class="textprogress">
                        {{params.progressBar && params.progressBar.sleep && params.progressBar.sleep.current ?
                        params.progressBar.sleep.current + "/" + params.progressBar.sleep.all :
                        params.progressBar.percentage}}
                    </span>
                    <div class="progress" *ngIf="params.progressBar?.percentage">
                        <div class="determinate" [style.width]="params.progressBar.percentage"></div>
                    </div>
                </div>
                <div class="divdesc">
                    <span class="newDesc"> {{ params.description?.value | translate }} </span>
                </div>
            </div>
        </div>
    </div>
</div>