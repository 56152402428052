import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { RequestsManagerService } from "../service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MaterialModule } from "../../material.module";

import { RequestsManagerPreviewDetailModule } from "./detail/module";
import { RequestsManagerPreviewHistoryModule } from "./history/module";
import { RequestsManagerPreviewCommentsModule } from "./comments/module";
import { RequestsListRecyclearViewValidateModule } from "./validate/module";
import { RequestsPreviewUpdatedModule } from "./update/module";
import { RequestsPreviewGuestInformationModule } from "./guest-information/module";

import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { ComponentModalConfirmedModule } from "../../components/modal-confirmed/module";
import { ComponentModalPreviewImgModule } from "../../components/preview-Img/module";

@NgModule({
  declarations: [
    MyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    TooltipModule,
    Ng2SearchPipeModule,
    RequestsManagerPreviewCommentsModule,
    RequestsManagerPreviewHistoryModule,
    RequestsManagerPreviewDetailModule,
    ComponentModalConfirmedModule,
    RequestsPreviewUpdatedModule,
    RequestsListRecyclearViewValidateModule,
    RequestsPreviewGuestInformationModule,
    ComponentModalPreviewImgModule
  ],
  exports: [
    MyComponent
  ],
  providers: [RequestsManagerService],
})
export class RequestsManagerPreviewModule { }
