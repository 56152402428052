<div class="modal up ModalDisconnet">
  <div class="modal-content text-inicial">
    <div class="contFoot col s12 padding0">
      <i  class="iconwaring material-icons left iconDIsconet">warning</i>
      <span class=" titlepop col s12 ">{{ 'The connection has been lost.' | translate }}</span>
      <span style="font-weight: bold ; width: 40%;" class=" titlepop col s5 ">{{ 'Update' | translate }}</span>
    </div>
    <!-- <div class=" contFoot col s12 padding0">
      <span class="col s12">{{ 'Please check your network settings.' | translate }} </span>
    </div> -->
  </div>
</div>
<div class="modal-overlay ups"></div>