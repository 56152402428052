<div class="col s12 padding0 content-detail scrolling-gavete" #scrollMe>
  <p class="col s12 m12 xl12"></p>
  <div class="col s12 l12 xl12 white padding-base padding0">
    <div style="margin-left: 1.8rem; margin-bottom: 1rem;" class="col s12 l12 m12 xl12">
      <span class="color-base font-14 subtext-preview " style="margin-bottom: 1rem;">{{ request.requestText | titlecase
        }} </span>
    </div>
    <div style="margin-left: 1.8rem; margin-bottom: 1rem;" class="col s12 l12 m12 xl12"
      *ngIf="request.state != 'validate'">
      <span class="color-base font-14 subtext-preview " style="margin-bottom: 1rem;">
        <ng-container *ngIf="request.suggestedSubcategory">
          {{ request?.suggestedSubcategory }}
          / {{ 'Suggested' | translate}} / {{ request.department }}
        </ng-container>
        <ng-container *ngIf="!request.suggestedSubcategory">
          {{ request.subcategory }}
          / {{ request.category }} / {{ request.department }}
        </ng-container>
      </span>
    </div>
    <div class="col s12 m6 xl6 l6 padding0 modal-padding-leftright">
      <div class="col s12 l12 m12 xl12 padding0">

        <span class="color-base-two font-12 semiblod">
          {{'Status' | translate | uppercase}}
        </span>
      </div>

      <div class="col s5 l10 xl10 m10 padding0" *ngIf="request.otherStatuses.length > 0">
        <a class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ request?.statusColorHex }} z-depth-2"
          [matMenuTriggerFor]="menu" style="margin-top:8px;margin-bottom: 8px;">
          <span id="pending" class="semiblod ">
            {{ request.statusName | translate }}
          </span>
          <i class="material-icons">edit</i>
        </a>
      </div>

      <a class="btn btn-block btn-small-s btn-flat-ant {{ request?.statusColorHex }} pointer col s12 l10 xl10 m10 btn-f-b-flat noPointer padding0 margin-bottom10"
        *ngIf="request.otherStatuses.length === 0 && request.state != 'validate'">
        <span> {{ request.statusName | translate}}</span>
      </a>

      <a class="btn btn-block btn-small-s btn-flat-ant {{ request?.statusColorHex }} pointer col s12 l10 xl10 m10 btn-f-b-flat noPointer padding0 margin-bottom10"
        *ngIf="request.otherStatuses.length === 0 && request.state == 'validate'"
        (click)="updateStatus({codename: 'validate'})">
        <span> {{ request.statusName | translate}}</span>
      </a>

      <mat-menu #menu="matMenu">
        <a *ngFor="let act of request.otherStatuses"
          class="btn btn-block btn-small-s btn-flat-ant pointer col s12 btn-f-b-flat {{ act?.colorHex }} menuOption"
          (click)="statusSelected = act">
          <span id="{{act.colorHex}}" class="semiblodBtn ">
            {{ act.name | translate}}
          </span>
        </a>
      </mat-menu>

      <!-- updateStatus(act) -->
      <app-requests-quick-comment *ngIf="statusSelected"
        (exitComment)="sendQuickComment($event)"></app-requests-quick-comment>

      <div class="col s12 m10 l10 xl10 padding0"
        *ngIf="request.state == 'active' && request.statusCodename != 'onhold' && request.endDate">
        <app-requests-manager-timer class="position5" [startDate]="request?.startDate" [endDate]="request?.endDate"
          [percentageWarning]="'25'" [percentageDanger]="'10'" [countSumate]="countSumate"
          (state)="updateStateTime($event)">
        </app-requests-manager-timer>

      </div>
      <div class="col s12 l12 m12">
        <br />
      </div>
    </div>


    <div class="col s12 padding0">


      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-when">
          <i class="material-icons">query_builder</i>
        </div>

        <div class="margin0">
          <b class="semiblod-color-base font-12 context-option">
            {{'When?' | translate | uppercase}}</b><br />
          <div class="divintercf">
            <span class="relative-text  font-16 regular context-option">
              {{ request.executionDate | date:'d MMM yyyy':'TZ' : langug }}
              <span class="semiblod-color-base">
                {{ request.executionDate | date:'h:mm a':'TZ' : langug }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="col s12"><br></div>

      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-where">
          <i class="material-icons">location_on</i>
        </div>
        <div class="margin0">

          <b class="semiblod-color-base font-12 context-option">
            {{'Where?' | translate | uppercase}}</b><br />
          <div class="divintercf">
            <div style="display: flex;" class="" *ngIf="!editRoom;else editRoomElse;">
              <span class="relative-text color-base font-16 regular context-option">
                <ng-container *ngIf="request.publicArea">
                  {{ request.publicArea }}
                  <span class="etiqueta where-btn">
                    {{'Public area' | translate}}
                  </span>
                </ng-container>
                <ng-container *ngIf="!request.publicArea">
                  {{ request.roomNumber }}
                  <span class="etiqueta where-btn">
                    {{'Room' | translate}}
                  </span>
                </ng-container>
              </span>
              <!-- <div *ngIf="auth.userHasScopes(['tasks:update']) && !request.statusCodename != 'finished'"
                style="margin-left: 0.5rem;" class="input__message__button" (click)="editRoom=true;">
                <i class="material-icons input__message__button__icon">edit</i>
              </div> -->
            </div>
            <ng-template #editRoomElse>
              <div class="col s8">
                <input maxlength="50"
                  (keyup.enter)="updateRoom(request.publicArea ? 'public' : 'room', $event.target.value)"
                  (focusout)="updateRoom(request.publicArea ? 'public' : 'room', $event.target.value)"
                  [value]="request[request.publicArea ? 'publicArea' : 'roomNumber']" id="first_name" type="text"
                  class="validate">
                <span class="edit-text">{{"Press 'enter' to save" | translate}}</span>
              </div>

            </ng-template>
          </div>

        </div>

      </div>

      <div class="col s12"><br></div>

      <div class="col s12 padding0 modal-padding-leftright">
        <div class="left circle-who">
          <i class="material-icons">{{!request?.isInternal ? "person" : "home"}}</i>
          <i style="top: 0px; left: 5px; font-size: 1rem" class="material-icons badge-icon"
            *ngIf="request.reservationVip">star</i>
        </div>
        <div class="margin0" (click)="getInformationReservation()" [ngClass]="{'pointer': request.reservationId}">
          <b class="semiblod-color-base font-12 context-option">
            {{'Who?' | translate | uppercase}}</b><br />
          <div class="divintercf">

            <span class="relative-text semiblod-color-base font-16 regular context-option">
              {{request.guestName | slice:0:20}}
              <span *ngIf="request.roomNumber" class="semiblod-color-base font-16">
                {{'Room' | translate}} {{request.roomNumber}}
              </span>

              <span class="etiqueta normal">
                {{ (request.isInternal ? 'Internal' : 'Guest') | translate }}
              </span>

            </span>

          </div>
        </div>
        <!-- <div class="col s12 l12 xl12 m12 border__top__4line padding0" style="margin-top: 31px; margin-bottom: -30px;">
          <br>
        </div> -->
      </div>
    </div>
  </div>
  <div class="col s12 l12 xl12 m12"><br></div>

  <div class="col s12 l12 xl12 white padding0 modal-padding-leftright">

    <div class="col s12 border__top__4line border-line "><br /></div>

  </div>
  <div class="col s12 padding0 modal-padding-leftright" style="margin-top:-15px ;">
    <br />

    <div class="row">
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Priority' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 regular">
          <a class="pointer col s12 margin-top-status padding0" style="margin-top:13px;">
            <span id="pending" class="semiblodBtn color-base position-relative-5">
              <i
                class="material-icons iconarrowprio left margin-rigth5 position-relative5 {{request.priorityColorHex}}">
                {{request.priorityIcon }}
              </i>
              {{ request.priorityName | translate }}
            </span>

            <i style="float: right;" class="material-icons  color-base-two" [ngClass]="{'no__pointer': request.statusCodename == 'finished'}"
              [matMenuTriggerFor]="menu3">arrow_drop_down</i>
          </a>

          <mat-menu #menu3="matMenu" class="foundChangeStatusMenu col s12 l12 m12 margin-left-80"
            style="margin-left: -80px !important;">
            <a *ngFor="let priority of priorities" class="center" mat-menu-item (click)="updatePriority(priority)"
              style="margin-top: 1%;" class="clasitemselect">
              <div class="clasinteritem">
                <div class="diviconarr">
                  <i *ngIf="priority.icon"
                    class="material-icons iconarrowprio position-relative10 {{priority?.colorHex}}">
                    {{ priority.icon }}
                  </i>
                  <span class="color-base relative-5">
                    {{ priority.name | translate }}
                  </span>
                </div>


              </div>
            </a>
          </mat-menu>
        </span>
      </div>
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Assignee' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 regular">
          <mat-form-field class="example-full-width col s12 padding0" 
            [ngClass]="{'asigne-free':!request.assigneeFirstName,'asigne-no-free':request.assigneeFirstName}">

            <mat-chip-list #chipList class=" margin-top10" >

              <mat-chip *ngIf="request.assigneeFirstName" class="itememail" selectable="true" removable="true"
                (removed)="removeAssignee()" class="mat-chip-custom widht90">
                <span class="textitemsum cut-text">{{ request.assigneeFirstName + " " +
                  request.assigneeLastName
                  }}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>

            </mat-chip-list>

            <input *ngIf="!request.assigneeFirstName" class="classinput margin0" #eventsInput type="text" [disabled]="request.statusCodename == 'finished'"
              placeholder="{{ 'Select assignee' | translate }}" matInput [matAutocomplete]="auto"
              (keyup)="searcAssignee = $event.target.value;">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateAssignee($event)">
              <mat-option *ngFor="let user of users | filter: searcAssignee" [value]="user">
                <span class="textstyle" [ngClass]="{'textUppercase': user.disabled}">
                  {{ user.name }}
                </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Request type' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 regular">
          {{ (request.isInternal ? 'Internal' : 'Guest') | translate }}
        </span>
      </div>
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Due Time' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 regular">
          {{ request.dueDate | date:'d MMM yyyy':'TZ' : langug }} {{ request.dueDate | date:'h:mm a':'TZ' : langug
          }}
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Maximum “New” Status Time' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 color-base font-16 regular">
          00:{{ request.newStatusTimeMinutes < 10 ? '0' + request.newStatusTimeMinutes : request.newStatusTimeMinutes }}
            min </span>
      </div>
      <div class="col s12 m6 padding0">
        <span class="color-base-two font-12 semiblod">
          {{ 'Maximum “Accepted” Status Time' | translate | uppercase }}
        </span><br />
        <span class="two color-base font-16 color-base font-16 regular">
          00:{{ request.timeExecutionMinutes < 10 ? '0' + request.timeExecutionMinutes : request.timeExecutionMinutes }}
            min </span>
      </div>
    </div>

    <div class="row" *ngIf="request.subcategoryFollowUp">
      <div class="col s12 m6 padding0" *ngIf="request.subcategoryFollowUp">
        <span class="color-base-two font-12 semiblod">
          {{ 'Maximum “Verification” Time' | translate | uppercase }}
        </span><br>
        <span class="two font-16">
          {{ request.followUpTimeHours < 10 ? '0' + request.followUpTimeHours : request.followUpTimeHours }}h {{
            request.followUpTimeMinutes < 10 ? '0' + request.followUpTimeMinutes : request.followUpTimeMinutes }}m
            </span>
      </div>
      <div class="col s12 m6 padding0"></div>
    </div>

    <div class="col s12 padding0" *ngIf="request.followUpQuestions.length > 0">
      <span class="color-base font-16 semiblod">
        {{ 'Follow up questions' | translate }}
      </span><br>
      <ul>
        <li *ngFor="let fuq of request.followUpQuestions">
          <div class="input-field col s12">
            <label class="color-base-two font-12 semiblod margin-top-20"
              style="transform: translateY(-14px) scale(.8);">
              {{ fuq.question | translate | uppercase}}
            </label>
            <input style="height: 2rem; margin-top: 4%;" id="fuq-id{{ fuq.question }}" type="text" class="validate"
              autocomplete="off" ngcontrol [(ngModel)]="fuq.reply"
              (keyup)="onKeyUpFuq(fuq.question, $event.target.value)">

          </div>
        </li>
      </ul>
    </div>

    <div class="col s12 l12 m12 xl12">
      <br />
    </div>
  </div>
</div>