<div  class="modal up" style="z-index: 1000;">
    <a (click)="close.emit()" class="round-button m-header right close-btn">
        <i class="material-icons">close</i>
    </a>
    <a *ngIf="img.imgList.length > 1" class="round-button float-button prev" (click)="getPrev(img.imgList, i)">
        <i class="material-icons">arrow_back_ios_new</i>
    </a>
    <a *ngIf="img.imgList.length > 1" class="round-button float-button next" (click)="getNext(img.imgList, i)">
        <i class="material-icons">arrow_forward_ios</i>
    </a>
    <div class="img-area" role="listbox">
        <img class="img-pre" [src]="url" alt="slide">
    </div>
    
</div>
<div class="modal-overlay pointer ups" style="z-index: 999 !important;" (click)="close.emit()" >
</div>