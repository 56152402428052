import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { ProgrammingRepeatService } from '../../../components/programing-repeat/service';

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [MyComponent],
  providers: [ProgrammingRepeatService],
})
export class RequestsManagerPreviewHistoryModule { }
