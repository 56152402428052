import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MyComponent } from "./component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [MyComponent],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
  exports: [MyComponent],
  providers: [],
})
export class RequestsQuickCommentModule { }
