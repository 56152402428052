<div class="dropdiv" id="scrollnotify">
    <div class="col s12 padding0 mrg__bot16">
        <span class="title__not">{{'Notifications' | translate}}</span>
        <div class="gh-menu-option">
            <a href="javascript:;" (click)="showDropdownMoreOptions = !showDropdownMoreOptions">
                <i class="material-icons icon__more" data-target='dropdown__more'>more_vert</i>
            </a>

            <div class="hidden-backgound" *ngIf="showDropdownMoreOptions" (click)="showDropdownMoreOptions = false;">
            </div>
            <ul id='dropdown__more' class='dropdown-content' [ngClass]="{'inDropDown':showDropdownMoreOptions}">
                <li class="cont-li">
                    <a class="txt-dropdown" (click)="clearAllNotifications();showDropdownMoreOptions = false;">
                        <i class="material-icons icon-margin">delete</i>
                        {{ 'Delete all' | translate }}
                    </a>
                </li>
                <li class="cont-li">
                    <a class="txt-dropdown" routerLink="/settings/rules">
                        <i class="material-icons icon-margin">settings</i>
                        {{ 'Go to rules' | translate}}
                    </a>
                </li>
            </ul>


        </div>
    </div>
    <div class="col s12 padding0">
        <app-guesthub-component-loading *ngIf="loading"></app-guesthub-component-loading>
        <ul id='dropdown1' class="margin0" *ngIf="!loading">
            <li *ngFor="let notification of notifications" class="border__bot">
                <app-notification-detail [notification]="notification"
                    (onAction)="onAction.emit($event)"></app-notification-detail>
            </li>

            <li *ngIf="notifications.length == 0">
                <div class="widitem margin0">
                    <div class="textnot center-align">
                        <i class="material-icons icon__check">check</i>
                        <span class="textnotif">{{ 'No notification' | translate }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="modal-overlay ups" (click)="onClose()"></div>