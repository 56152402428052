import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { RequestsListRecyclearViewGuestItemPreviewComponent } from "./component";
import { RequestsManagerService } from "../../service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { MaterialModule } from "../../../material.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
  declarations: [RequestsListRecyclearViewGuestItemPreviewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule
  ],
  exports: [RequestsListRecyclearViewGuestItemPreviewComponent],
  providers: [RequestsManagerService],
})
export class RequestsListRecyclearViewValidateModule {}
