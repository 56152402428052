import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingModule } from './routing.module';
import { MyComponent } from './component';
import { NavigationHeaderTitleService } from './service';
import { MaterialModule } from '../../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { WelcomeService } from "../../../welcome/service";

@NgModule({
    declarations: [
        MyComponent
    ],
    imports: [
        CommonModule,
        RoutingModule,
        MaterialModule,
        TranslateModule,
        TooltipModule
    ],
    exports: [
        MyComponent
    ],
    providers: [
        NavigationHeaderTitleService,
        WelcomeService
    ],
})
export class HeaderTitleModule { }
