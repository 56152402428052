import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GearIconComponent } from './component';
import { GearIconService } from './service';
import { MaterialModule } from '../../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [
        GearIconComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        TooltipModule,
        BsDropdownModule
    ],
    exports: [
        GearIconComponent
    ],
    providers: [
        GearIconService
    ],
})
export class GearIconModule { }
