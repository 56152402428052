<div id="modal-img" class="modal center up center-img-modal col s10 xl5"
    style="background-color: var(--gh-color-white);"
    [ngClass]="{'height-edit':image, 'modal__off__edit': !image && !loaderImgActive}">

    <div class="m-header">
        <h4 style="padding: 9px">
            <strong class="center font_title_modal"></strong>
            <a (click)="closeModalEvent()" class="right">
                <i class="material-icons">close</i>
            </a>
            <br>
        </h4>
    </div>

    <div *ngIf="!loadingImg" style="background-color: var(--gh-color-white);"
        [ngClass]="{'info-area': !(imageChangedEvent || image)}"
        [ngStyle]="(imageChangedEvent || image) && {'height': '400px'}">
        <div class="col s12 padding-card-img">
            <div class="col s12 padding0 border-select-img" *ngIf="!imageChangedEvent && !image ">
                <div class="file-field input-field margin0">
                    <div class="btn-select btn-selected-file">
                        <input type="file" (change)="fileChangeEvent($event)" accept="image/x-png,image/jpeg">
                    </div>
                    <div class="">
                        <div class="">
                            <br>
                            <a class="button-fake"><i class="add-fake">+</i></a>
                            <span class="text-button">
                                {{'Upload image' | translate }}
                            </span>
                        </div>
                        <input class="file-path validate" type="text">
                    </div>
                </div>
                <div class="col s12 padding0 center">
                    <span class="text-info-img">
                        {{'Drag and drop the image from your device.' | translate}}
                    </span>
                    <br>
                    <span class="text-info-img">
                        {{'The supported file types are .png or .jpg up to 10mb' | translate}}
                    </span>
                </div>
                <div class="col s12 l12 m12">
                    <br>
                </div>
            </div>

            <div class="col s12  padding0 img-format" [ngClass]="{'hidden': !(imageChangedEvent || image)}">
                <image-cropper *ngIf="!loadingImg" class="padding0 thumbnails" [imageURL]="image"
                    [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" left [resizeToWidth]="width"
                    [resizeToHeight]="height" format="jpg" (imageCropped)="imageCropped($event)" [imageQuality]="100"
                    (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed($event)" [transform]="transform" [aspectRatio]="ratio"
                    [canvasRotation]="canvasRotation">
                </image-cropper>
            </div>
            <div class=" col s12 padding0 menu-relative" *ngIf="imageChangedEvent || image">
                <div class="col s12 l12 m12 xl12">
                    <mat-divider></mat-divider>
                </div>
                <div class="col s12  padding0">
                    <div class="col s12 l12 m12">
                        <br>
                    </div>
                    <div class="col s6 l6 m6 xl6">
                        <span class="left col s12 control-title">
                            {{'ZOOM' | translate}}
                            <br>
                        </span>
                        <div class="col s12">
                            <div class="col s2 padding0 ">
                                <i class="material-icons zoom-reset pointer" (click)="zoomOut()">zoom_out</i>
                            </div>
                            <div class="col s8 padding0 zoom-slice">
                                <mat-slider thumbLabel [displayWith]="formatLabel" [(ngModel)]="scale"
                                    (change)="changesZoom($event.value)" min="1" step="0.1" max="3" aria-label="units">
                                </mat-slider>
                            </div>
                            <div class="col s2 padding0 left">
                                <i class="material-icons zoom-add pointer" (click)="zoomIn()">zoom_in</i>
                            </div>
                        </div>
                    </div>
                    <div class="col s6 l3 m3 xl3 tilt-area">
                        <span class="left col s12 l12 m12 control-title">
                            {{'Tilt' | translate}}
                            <br>
                        </span>
                        <div class="col s12 l12">
                            <div class="col s6">
                                <i class="material-icons pointer left " (click)="rotateLeft()">rotate_left</i>
                            </div>
                            <div class="col s6">
                                <i class="material-icons pointer right " (click)="rotateRight()">rotate_right</i>
                            </div>
                        </div>
                    </div>
                    <div class="col s6 l3 m3 xl3 rotate-area">
                        <div class="col s6 control-title">
                            {{'Rotate' | translate}}
                        </div>
                        <div class="col s6 control-title">
                            {{'Mirror' | translate}}
                        </div>
                        <div class="col s6">
                            <a class="pointer" (click)="flipHorizontal()" tooltip="{{ 'Flip horizontal' | translate }}"
                                container="body" placement="top"><i class="material-icons">360</i></a>
                        </div>
                        <div class="col s6">
                            <a class="pointer right" (click)="flipVertical()"
                                tooltip="{{ 'Flip vertical' | translate }}" container="body" placement="top">
                                <i class="material-icons">rotate_90_degrees_ccw</i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="imageChangedEvent || image" class="col s12 padding0 center m-footer">
        <div style="margin-bottom: 8px;" class="col s12 l12 m12 xl12">
            <mat-divider></mat-divider>
        </div>
        <div class="file-field input-field margin0 col s6">
            <div class="right">
                <span class="reset-img font16 semiblod ">{{'Select image' | translate}}</span>
                <input type="file" (change)="fileChangeEvent($event)" accept="image/x-png,image/jpeg">
            </div>
        </div>
        <div class="col s6">
            <a class="pointer btn font16 left" (click)="save()">
                {{ 'Save image' | translate}}</a>
        </div>
    </div>

    <div class="col s12 l12 xl12 m12 center">
        <div class="loadingdiv" *ngIf="loadingImg">
            <br>
            <br>
            <br>
            <br>
            <br>
            <div class="preloader-wrapper big active">
                <div class="spinner-layer spinner-green-only">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal-overlay ups" (click)="closeModalEvent()"></div>