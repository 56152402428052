import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { MyComponent } from './component';

import { MyProfileService } from "../service";

@NgModule({
    declarations: [
        MyComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TooltipModule
    ],
    exports: [
        MyComponent
    ],
    providers: [MyProfileService]
})
export class MyProfileChangePasswordModule { }
