import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlBuilderService } from '../../services/url-builder.service';

@Injectable()
export class RequestCommentsService {
    constructor(
        private http: HttpClient,
        private urlbuilder: UrlBuilderService,
    ) { }

    getTicketComments(ticketId: number): Promise<any> {
        return this.http.get<any>(this.urlbuilder.getTicketComments(ticketId))
            .toPromise()
            .catch(console.error);
    }

    getTicketHistory(ticketId: number): Promise<any> {
        return this.http.get<any>(this.urlbuilder.getTicketHistory(ticketId))
            .toPromise()
            .catch(console.error);
    }

    create(comment: any, ticketId: any): Promise<any> {
        return this.http.post<any>(this.urlbuilder.postTicketComment(ticketId), { body: comment })
            .toPromise()
    }
    uploadPhotos(formData: any) {
        return this.http.post<any>(this.urlbuilder.CommunicationsSurvey().EmailTemplate().uploadPhoto(),
            formData)
            .toPromise()
            .catch(console.error);
    }
}
