<div class="modal-content text-inicial white">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob"
        style="margin-bottom: 5px; margin-top: 8px !important; padding-left: 8px !important;">
        <i class="material-icons left pointer color-base-two" (click)="cancel()">chevron_left</i>
    </div>
    <div *ngIf="!loading">
        <h2 class="col s12 color-base font24 blod modal-padding-leftright marg-top marg-bot">
            {{ title | translate }}
        </h2>
        <div class="clearfix"></div>
        <div class=" modal-padding-leftright scroll-hei">
            <div class="info-area">
                <div class="icon-area">
                    <i class="material-icons feedback feedRed">mood_bad</i>
                    <i class="material-icons badge-icon" *ngIf="request.reservationVip">star</i>
                </div>
                <div class="guest-area">
                    <span class="where-text textEllipsis">
                        <ng-container *ngIf="request.publicArea">
                            {{ request.publicArea }}
                        </ng-container>
                        <ng-container *ngIf="!request.publicArea">
                            {{ request.roomNumber }}
                        </ng-container>
                    </span>
                    <span class="who-text textEllipsis">{{request.guestName | slice:0:20}}</span>
                </div>
                <div class="description-area" *ngIf="!request.type">
                    <span class="desc-text textEllipsis">
                        <ng-container *ngIf="request.suggestedSubcategory">
                            {{ request?.suggestedSubcategory }}
                            / {{ 'Suggested' | translate}} / {{ request.department }}
                        </ng-container>
                        <ng-container *ngIf="!request.suggestedSubcategory">
                            {{ request.subcategory }}
                            / {{ request.category }} / {{ request.department }}
                        </ng-container>
                    </span>
                    <span class="req-text textEllipsis">{{ request.requestText }}</span>
                </div>
                <div class="description-area bolded" *ngIf="request.type">
                    <span class="desc-text textEllipsis">
                        {{ parseListItems() }}
                    </span>
                    <span class="req-text textEllipsis">
                        <i class="material-icons">shopping_cart</i>
                        <span class="price">{{ request.typeMoney }} {{ request.priceTotal }}</span>
                    </span>
                </div>
            </div>
            <span class="textFeedback textNegative">
                {{ (request.feedbacks[0].commentSurvey?.length > 0 ? request.feedbacks[0].commentSurvey : "I didn't
                like") | translate }}
            </span>

            <div>
                <div style="padding: 0;" class="input-field input-field--name col s12">
                    <span class="left font12 labels"
                        [ngClass]="{'active': focusComment, 'field-error': comment.length == 0 && comm.touched } ">
                        {{'What action did you take to satisfy the guest?' | translate | uppercase}}
                    </span>

                    <textarea appTextareaAutoresize autofocus
                        placeholder="{{ 'Ex A discount was given to the guest...' | translate}}" type="text"
                        maxlength="200" (blur)="focusComment = false" (focus)="focusComment = true"
                        [ngClass]="{'select-wrapper.valid': focusComment, 'requeride' : comment.length == 0 && comm.touched }"
                        [(ngModel)]="comment" (keyup)="limitField($event); changeContent()" class="validate"
                        autocomplete="off" #comm="ngModel" #inputAutofocus></textarea>
                    <span class="input-alert-handle">
                        <span class="helper-text txt-field field-error" *ngIf="comment.length == 0 && comm.touched">
                            {{ 'Required' | translate }}
                        </span>
                        <span class="helper-text right txt-count">
                            {{ comment?.length || 0 }}/200
                        </span>
                    </span>
                </div>
            </div>
        </div>

        <div class="nopadding mygavete-buttons">
            <div class="right-align">
                <button (click)="cancel()" class="btn btn-falt-ant btn-small button-cancel" container="body"
                    placement="top">
                    {{ "Cancel" | translate }}
                </button>
                <button (click)="openDialogConfirm()" [disabled]="disabledButton"
                    class="btn btn-falt-ant btn-small button-save" container="body" placement="top">
                    {{ "Save" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="loadingdiv col s12 m12 xl12 center " *ngIf="loading">
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</div>