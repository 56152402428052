import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { MyComponent } from './component';
import { ComponentLoadingModule } from "../../../components/loading/module";
import { DirectivesModule } from "../../../components/directives/module";
import { GuestsListService } from "../../../guests/list/service";

import { RequestsManagerService } from "../../service";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MaterialModule } from '../../../material.module';

@NgModule({
    declarations: [
        MyComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TooltipModule,
        DirectivesModule,
        Ng2SearchPipeModule,
        ComponentLoadingModule,
        MaterialModule,
        ReactiveFormsModule
    ],
    exports: [
        MyComponent
    ],
    providers: [RequestsManagerService, GuestsListService]
})
export class RequestsPreviewUpdatedModule { }
