<div class="divider">
</div>
<div class="">
  <div class="text-left col xl9 col l9 col m8 col s12">
    <span style="font-family: 'Open Sans', sans-serif !important; !important;float: left !important;">
      Norelian LLC &copy; {{ dateYear }}</span>
    <span
      style="font-family: 'Open Sans', sans-serif !important; !important;margin-left: 10px !important;">{{ 'Copyright' }}</span>
  </div>
  <div class="text-right col xl3 col l3 col m4 col s12">
    <span style="font-family: 'Open Sans', sans-serif !important; !important"
      class="empresa-footer">{{ today | date:'fullDate' }}</span>
  </div>
</div>