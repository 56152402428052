<div style="margin-top: 40px; margin-bottom: 40px; display: flex; justify-content: center;">
  <div class="left">
    <img class="right" src="assets/images/requests/withoutResults.svg" style="width: 75%; margin-right: 10px;" />
  </div>
  <div class="right">
    <h1
      style="color: var(--gh-color-letter); font: normal normal 600 40px/50px Open Sans; font-size: 30px !important; padding: 0; margin: 0;">
      {{ 'No results' | translate }}
    </h1>
    <h5
      style="color: var(--gh-color-letter); font: normal normal normal 16px/22px Open Sans; font-size: 14px !important; padding: 0; margin: 0 0 0 0;">
      {{ 'Modify your search criteria' | translate }}
    </h5>
  </div>
  <div class="clearfix"></div>
  <br>
</div>