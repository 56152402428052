import { Component, OnInit, } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-guesthub-footer',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class FooterComponent implements OnInit{ 

  today: any;
  dateYear: number = new Date().getFullYear();
  
  constructor(){
    
  }
  
  ngOnInit(){
    this.today = Date.now();
  }
   
}

