import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-requests-quick-comment",
  templateUrl: "./template.html",
  styleUrls: ["./styles.css"],
})
export class MyComponent implements OnInit {
  @ViewChild("inputAutofocus") inputAutofocus: ElementRef;
  @Output() exitComment = new EventEmitter<String>();
  @Input() status: any = null;
  txtComment = "";

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.inputAutofocus) {
        this.inputAutofocus.nativeElement.focus();
      }
    }, 500);
  }

  updateRoom(value) {
    this.exitComment.emit(value.toString().trim());
  }
}
