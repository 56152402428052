import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-guesthub-redirect-to',
  templateUrl: 'template.html',
  styleUrls: []
})
export class RedirectToComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.propertyId && params.uri) {
        let regex: any = /(\d+)/g;
        let detail: any = params.uri.match(regex)
        this.router.navigate([params.uri]);
        if(localStorage.getItem('chosen_property') != params.propertyId.toString()){
          setTimeout(() => (location.reload()), 1000)
        }
        localStorage.setItem('chosen_property', params.propertyId.toString())
      }
    });
  }
}
