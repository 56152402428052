import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";

//import { RulesModule } from "./rules/module";
import { UrlBuilderService } from "./services/url-builder.service";
import { GetRequestService } from "./services/get-request.service";
import { GetTicketsService } from "./services/get-tickets.service";
import { GetDepService } from "./services/get-departments.service";
import { PostInviteUserService } from "./services/post-inviteUser.service";
import { PostResetUserPasswordService } from "./services/post-resetUserPassword.service";
import { LoadingContainerModule } from "./loading-indicator/loading-container.module";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { GuesthubContextService } from "./services/guesthub-context.service";
import { GetPermissionsService } from "./services/get-permissions.service";
import { Ng5BreadcrumbModule } from "ng5-breadcrumb";
import { FooterComponent } from "./footer/component";
import { DatePipeModule } from "./datepipe/datepipe.module";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeEs from "@angular/common/locales/es";
import localePt from "@angular/common/locales/pt";
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localePt);

import { RedirectToModule } from "./redirect-to/module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { RequestCommentsModule } from "./requests/comments/module";
import { TopNavigationModule } from "./navigation/top/module";
import { SideNavigationModule } from "./navigation/side/module";

import { DateRangePickerModule } from "@syncfusion/ej2-angular-calendars";
import { NavigationHeaderTitleService } from "./navigation/top/header-title/service";
import { GuesthubInterceptor } from "./services/interceptor";
import { TopService } from "./navigation/top/service";
import { FooterModule } from "./footer/module";
import { AngularResizedEventModule } from "angular-resize-event";

import {
  ModuleTranslateLoader,
  IModuleTranslationOptions
} from "@larscom/ngx-translate-module-loader";

export function moduleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = "./assets/i18n";

  const options: IModuleTranslationOptions = {
    modules: [
      { baseTranslateUrl },
      { baseTranslateUrl: "./assets/i18n/restaurants" }
    ],
    translateError: (err, path) => console.error(path, err)
  };
  return new ModuleTranslateLoader(http, options);
}

import { MatMenuModule as MatMenuModule } from "@angular/material/menu";
import { HeaderTitleModule } from "./navigation/top/header-title/module";
import { FeaturesService } from "./features.service";
import { NamesTranslateService } from "./settings/request-configuration/translate.service";
import { Auth0Service } from "./auth0.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    FooterModule,
    LoadingContainerModule,
    CollapseModule,
    Ng5BreadcrumbModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: moduleHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DatePipeModule,
    ReactiveFormsModule,
    RedirectToModule,
    AppRoutingModule,
    TopNavigationModule,
    SideNavigationModule,
    RequestCommentsModule,
    DateRangePickerModule,
    AngularResizedEventModule,
    MatMenuModule,
    HeaderTitleModule
  ],
  exports: [FooterComponent],
  providers: [
    UrlBuilderService,
    GetRequestService,
    GetTicketsService,
    GetDepService,
    PostInviteUserService,
    PostResetUserPasswordService,
    GuesthubContextService,
    TopService,
    GetPermissionsService,
    FeaturesService,
    NavigationHeaderTitleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GuesthubInterceptor,
      multi: true
    },
    NamesTranslateService,
    Auth0Service
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
