import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaAutoresizeDirective } from "./textarea-directive";

@NgModule({
    declarations: [
        TextareaAutoresizeDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TextareaAutoresizeDirective
    ],
    providers: []
})
export class DirectivesModule { }
