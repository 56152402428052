import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ProgrammingRepeatService {
	daysOfWeek: Array<any> = [
		{
			name: 'Sunday',
			initial: 'initial-day-Sunday',
			value: 0
		},
		{
			name: 'Monday',
			initial: 'initial-day-Monday',
			value: 1
		},
		{
			name: 'Tuesday',
			initial: 'initial-day-Tuesday',
			value: 2
		},
		{
			name: 'Wednesday',
			initial: 'initial-day-Wednesday',
			value: 3
		},
		{
			name: 'Thursday',
			initial: 'initial-day-Thursday',
			value: 4
		},
		{
			name: 'Friday',
			initial: 'initial-day-Friday',
			value: 5
		},
		{
			name: 'Saturday',
			initial: 'initial-day-Saturday',
			value: 6
		}
	];
	listMonts: Array<any> = [
		{
			value: 1,
			name: "January"
		},
		{
			value: 2,
			name: "February"
		},
		{
			value: 3,
			name: "March"
		},
		{
			value: 4,
			name: "April"
		},
		{
			value: 5,
			name: "May"
		},
		{
			value: 6,
			name: "June"
		},
		{
			value: 7,
			name: "July"
		},
		{
			value: 8,
			name: "August"
		},
		{
			value: 9,
			name: "September"
		},
		{
			value: 10,
			name: "October"
		},
		{
			value: 11,
			name: "November"
		},
		{
			value: 12,
			name: "December"
		}
	];

	constructor(private translate: TranslateService) { }


	allDaysRepeat(data): string {
		return this.daysOfWeek.reduce((array, day) => {
			if (data.repeatDayOfWeek.indexOf(day.value) > -1) {
				array.push(`${data.repeatDayOfWeek.length == 1 || data.repeatDayOfWeek.indexOf(day.value) == 0
					? ''
					: data.repeatDayOfWeek.indexOf(day.value) == (data.repeatDayOfWeek.length - 1)
						? ` ${this.translate.instant('and')} `
						: ', '}${this.translate.instant(day.name)}`);
			}

			return array;
		}, []).join("");
	}

	getMonthDetail(data): string {
		return this.translate.instant(this.listMonts.find(m => m.value == data.repeatMonth).name);
	}
}
