import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../../services/url-builder.service";

@Injectable({
    providedIn: 'root'
})
export class GearIconService {
    constructor(
        private http: HttpClient,
        private urlbuilder: UrlBuilderService
    ) {

    }

    protected observable = new Subject<any>();
    public goAdd: string = null;

    public next(item: any) {
        this.goAdd = item;
        this.observable.next(item);
    }

    public subscribe(callback: (item: any) => void) {
        this.observable.subscribe(callback);
    }

}
