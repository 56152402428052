import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MyComponent } from "./component";
import { MaterialModule } from "../../../material.module";
import { RequestsManagerTimerModule } from "../../timer/module";
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { RequestsManagerService } from "../../service";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RequestsQuickCommentModule } from "../../quick-comment/module";

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialModule,
    RequestsManagerTimerModule,
    Ng2SearchPipeModule,
    RequestsQuickCommentModule
  ],
  exports: [MyComponent],
  providers: [RequestsManagerService]
})
export class RequestsManagerPreviewDetailModule { }
