import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { LoadingContainer } from './loading-container';

@NgModule({
  declarations: [
    LoadingContainer
  ],
  providers: [
  ],
  imports: [
    FormsModule,
    CommonModule,
    AppRoutingModule,
  ],
  exports: [
    LoadingContainer
  ],
})
export class LoadingContainerModule { }