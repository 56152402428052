import {
  Component,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { EnabledIntegrationsService } from "./service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-guesthub-integrations-enabled",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"]
})
export class EnabledIntegrationsComponent implements AfterViewInit {
  @Output() enabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading: boolean = true;

  constructor(
    private service: EnabledIntegrationsService,
    private translate: TranslateService,
  ) { }

  ngAfterViewInit() {
    this.loading = true;
    this.service
      .hasAnyIntegrationEnabled()
      .then(result => {
        this.enabled.emit(result);
        this.loading = false;
      });
  }
  
 
}
