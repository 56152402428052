import { UrlBuilderService } from "../../services/url-builder.service";
import { Injectable, Inject } from "@angular/core";
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "jquery";
import { Auth0Service } from "../../auth0.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class EditorWebService {
  newTemplate: boolean;
  comments: any;

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) {}
  protected observable = new Subject<any>();

   data: any = {
    html: null,
  };
   next(item: any) {
    this.data = item ? item : this.data;
  }

   subscribe(callback: (item: any) => void) {
    this.observable.subscribe(callback);
  }

  uploadImgs(data) {
    data.append("userId", this.auth.userProfile.id);
    return this.http.post<any>(
      `${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/images/${
        this.auth.getChosenProperty
      }`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

   uploadPhotos(formData: any, route: string) {
    return this.http
      .post<any>(this.urlbuilder.uploadPhotoContainer(route), formData)
      .toPromise()
      .catch(console.error);
  }
   parseComments(comments: any) {
    this.comments = comments.map((m) => {
      if (
        m.body.indexOf("<img") > 0 ||
        (this.getImages(m.body) && this.getImages(m.body)[0])
      ) {
        const imgHtml = this.getImages(m.body);
        const ex = $(imgHtml[0]).attr("src");
        const newImg = `  
        <a href="${ex}" target="_blank" class="imgEditor" >
          ${imgHtml[0]}
        <a>
       
        `;

        m.body = m.body.split(imgHtml[0]).join(newImg);
      }
      if (m.author && m.author.name)
        m.author.name = m.author.name ? m.author.name.replace(/ /g, "") : null;
      return m;
    });

    return this.comments;
  }
  getImages(string) {
    let imgRex = /<img[^>]*>/g;
    return imgRex.exec(string);
  }
}
