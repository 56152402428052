import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReqDetailsCommentsComponent } from './component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RequestCommentsService } from './service';
import { EditorWebModule } from '../../components/wysiwyg-web-summernote/module';
import { MaterialModule } from '../../material.module';
import { SearchModuleParams } from './../../pipes/filter.module';

@NgModule({
    declarations: [
        ReqDetailsCommentsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        MaterialModule,
        EditorWebModule,
        SearchModuleParams,
        Ng2SearchPipeModule
    ],
    exports: [
        ReqDetailsCommentsComponent
    ],
    providers: [
        RequestCommentsService
    ],
})
export class RequestCommentsModule { }
