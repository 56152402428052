import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms'
import { TooltipModule } from "ngx-bootstrap/tooltip";

import { MyComponent } from './component';
import { ComponentModalChangeImagenModule } from "../components/modal-change-imagen/module";
import { ComponentLoadingModule } from "../components/loading/module";

import { MyProfileService } from "./service";

import { MyProfileChangePasswordModule } from "./change-password/module";

@NgModule({
    declarations: [
        MyComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TooltipModule,
        ComponentModalChangeImagenModule,
        ComponentLoadingModule,
        MyProfileChangePasswordModule
    ],
    exports: [
        MyComponent
    ],
    providers: [MyProfileService]
})
export class MyProfileModule { }
