import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../services/url-builder.service";
import { FeaturesService } from "../features.service";

@Injectable()
export class WelcomeService {
  constructor(
    private featureService: FeaturesService
  ) {}


  list() {
    return this.featureService.getAllModules();
  }
}
