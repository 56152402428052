import { NgModule } from '@angular/core'
import { GridCellDataPipe } from './grid-cell-data.pipe';
import { SplitBigWord10Pipe, SplitBigWord12Pipe, SplitBigWord15Pipe } from "./splitBigWords.pipe";
import { ReversePipe } from './date-reverse.pipe';
import { SafeHtmlPipe } from './htmlSafe.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    GridCellDataPipe,
    SplitBigWord10Pipe,
    SplitBigWord12Pipe,
    SplitBigWord15Pipe,
    ReversePipe,
    SafeHtmlPipe
  ],
  exports: [
    GridCellDataPipe,
    SplitBigWord10Pipe,
    SplitBigWord12Pipe,
    SplitBigWord15Pipe,
    ReversePipe,
    SafeHtmlPipe
  ]
})
export class ApplicationPipesModule { }
