import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Auth0Service } from "../../../../auth0.service";
import { TopService } from "../../service";

@Component({
  selector: "app-notification-detail",
  templateUrl: "./template.html",
  styleUrls: ["../../styles.scss"],
})
export class AppNotificationsComponent implements OnInit, OnDestroy {
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Input() notification;
  @Input() isPopup = false;
  opacity = 1;
  private timer: any;
  private fadeOutTimer: any;
  topServiceActiveSound: any;

  constructor(private auth: Auth0Service, private topService: TopService) { }

  ngOnDestroy() {
    this.clearTimers();
  }

  ngOnInit() {
    this.topServiceActiveSound = this.topService.refreshUserSoundPreferences$.subscribe((res) => {
      this.initComponent();
    });
    this.initComponent();
  }

  initComponent() {
    /**
     * this.auth.userPreferences['enabledSoundNotificationRequests']
     * Esta variable contiene si la preferencia del usuario es activar el sonido de REQUESTS o no.
     * En otros monitores este valor varia.
     */

    this.startTimer();
  }

  startTimer() {
    if (this.isPopup && !this.auth.userPreferences[this.notification.notificationSound]) {
      this.opacity = 1;
      this.clearTimers();
      this.timer = setTimeout(() => {
        this.startFadeOut();
      }, 5000);
    }
  }

  stopTimer() {
    this.opacity = 1;
    this.clearTimers();
  }

  startFadeOut() {
    this.fadeOutTimer = setInterval(() => {
      if (this.opacity > 0) {
        this.opacity -= 0.1;
      } else {
        this.clearTimers();
        // Lógica para destruir el componente
        this.onAction.emit({
          action: 'destroy',
          id: this.notification.id
        });
      }
    }, 100);
  }

  clearTimers() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.fadeOutTimer) {
      clearInterval(this.fadeOutTimer);
    }
    if (this.topServiceActiveSound) {
      this.topServiceActiveSound.unsubscribe();
      this.topServiceActiveSound = undefined;
    }
  }
}
