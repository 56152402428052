import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { RequestsManagerService } from "../../service";
import { TranslateService } from "@ngx-translate/core";
import {
  MatAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatAutocomplete as MatAutocomplete,
} from "@angular/material/autocomplete";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { UntypedFormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { EventService } from "../../../event.service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-requests-list-recyclerview-validate",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class RequestsListRecyclearViewGuestItemPreviewComponent
  implements OnInit, OnDestroy {
  @Output() output = new EventEmitter<boolean>();
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Input() request;
  resultsInitial: boolean = true;
  loading: boolean = true;
  loadingSearching: boolean = false;
  subscribeRequests: any;
  searchTextReal: string = "";
  searchInteralText: any;
  activeAclared: boolean = false;
  activeAddSuggesteed: boolean = false;
  goSearchInput: boolean = false;
  whatResults: { requests: any[]; suggestedes: any[] } = {
    requests: [],
    suggestedes: [],
  };
  newRequest: any = {
    id: null,
    requests: [],
  };

  separatorKeysCodes: number[] = [ENTER, COMMA];
  eventsCtrl = new UntypedFormControl();
  @ViewChild("eventsInput") eventsInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  departmentFilters: any = [];
  departments: any;
  suggestedDepartment: number;
  suggestedText: string = "";
  suggestedDepartmentName: any;
  showTimeReq: any = [];
  minDate: Date = new Date();
  itemSelect: any = null;
  validate: boolean = true;

  constructor(
    public router: Router,
    private service: RequestsManagerService,
    public translate: TranslateService,
    protected eventService: EventService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnDestroy() {
    if (this.subscribeRequests) this.subscribeRequests.unsubscribe();
    if (this.subscribeRequests) this.subscribeRequests = undefined;
  }

  ngOnInit() {
    this.loading = true;
    Promise.all([
      this.service.listAllDepartments({ state: 'active' }),
    ]).then(([departments]) => {
      this.departments = departments;
      this.departmentFilters = this.eventsCtrl.valueChanges.pipe(
        startWith(""),
        map((value) => (value ? this._filter(value) : this.departments))
      );
      this.newRequest.id = this.request.id;

      if (this.request && this.request.requestText && this.request.requestText.length > 0) {
        this.service.searchWhat(this.request.requestText)
          .then(
            (resultQuery) => {
              this.resultsInitial =
                resultQuery.subcategories.length > 0 ||
                  resultQuery.suggestedes.length > 0
                  ? true
                  : false;
              this.goSearchInput =
                resultQuery.subcategories.length > 0 ||
                  resultQuery.suggestedes.length > 0
                  ? true
                  : false;
              this.whatResults = {
                requests: resultQuery.subcategories.map((req) => ({
                  category: req.categoryName,
                  department: req.departmentName,
                  name: req.name,
                  id: req.id
                }
                )),
                suggestedes: resultQuery.suggestedes.map(req => ({
                  department: req.departmentName,
                  departmentId: req.departmentId,
                  name: req.name
                }
                ))
              };

              this.loading = false;
            }
          );
      } else {
        this.resultsInitial = false;
        this.goSearchInput = false;
        this.loading = false;
      }

      this.subscribeRequests = this.eventService.channels.request.subscribe((request) => {
        if (request.id == this.request.id && request.state != 'validate') {
          this.closeModal();
        }
      });
    });
  }

  private _filter(value: any): string[] {
    return this.departments.filter((department) =>
      department.name
        .toLowerCase()
        .includes(value.name ? value.name.toLowerCase() : value.toLowerCase())
    );
  }

  updateDepartmentSuggested(event): void {
    const department = event.option.value;
    this.suggestedDepartment =
      department && department.id ? department.id : null;
    this.suggestedDepartmentName =
      department && department.name ? department.name : null;
    this.eventsCtrl.setValue(this.suggestedDepartmentName);
    this.validate = true;
  }

  deleteInputDepartment(e) {
    this.validate = this.suggestedDepartment == e || this.suggestedDepartmentName == e ? true : false;
  }

  validButtonAddsuggested(): boolean {
    return this.suggestedText && this.suggestedText.toString().trim().length > 0 && this.suggestedDepartmentName && this.suggestedDepartmentName.toString().trim().length > 0;
  }

  cancelSuggested() {
    this.suggestedDepartment = null;
    this.suggestedText = null;
    this.suggestedDepartmentName = null;
    this.eventsCtrl.setValue(null);
    this.activeAddSuggesteed = false;
  }

  saveSuggested() {
    this.newRequest.requests.push({
      departmentName: this.suggestedDepartmentName,
      departmentId: this.suggestedDepartment,
      name: this.suggestedText,
      time: null,
    });
    this.suggestedDepartment = null;
    this.suggestedText = null;
    this.suggestedDepartmentName = null;
    this.eventsCtrl.setValue(null);
    this.activeAddSuggesteed = false;
  }

  closeModal() {
    this.output.emit(true);
  }

  goBack() {
    this.cancelEvent.emit(true);
  }

  countComments(): boolean {
    return this.request.comments.filter((c) => !c.read).length > 0;
  }

  onKeySearch(e) {
    clearInterval(this.searchInteralText);
    this.searchInteralText = undefined;
    this.searchTextReal = e;

    if (!this.searchInteralText) {
      let intervalInput = false;
      this.goSearchInput = false;
      this.loadingSearching = true;
      this.searchInteralText = setInterval(() => {
        if (!intervalInput && this.searchTextReal.length > 0) {
          intervalInput = true;
          this.service.searchWhat(this.searchTextReal)
            .then((resultQuery) => {
              this.goSearchInput = true;
              this.whatResults = {
                requests: resultQuery.subcategories
                  .map((req) => ({
                    category: req.categoryName,
                    department: req.departmentName,
                    name: req.name,
                    id: req.id
                  }))
                  .filter((req) =>
                    this.newRequest.requests.find(
                      (reqAdd) => reqAdd.id === req.id
                    )
                      ? false
                      : true
                  ),
                suggestedes: resultQuery.suggestedes
                  .map((req) => ({
                    department: req.departmentName,
                    departmentId: req.departmentId,
                    name: req.name
                  }))
                  .filter((req) =>
                    this.newRequest.requests
                      .filter((r) => !r.id)
                      .find((reqAdd) => reqAdd.name === req.name)
                      ? false
                      : true
                  )
              };

              clearInterval(this.searchInteralText);
              this.searchInteralText = null;
              intervalInput = false;
              this.loadingSearching = false;
            })
            .catch((err) => {
              console.error(err);
              this.loadingSearching = false;
              this.goSearchInput = false;
              clearInterval(this.searchInteralText);
              this.searchInteralText = undefined;
              intervalInput = false;
              this.whatResults = {
                requests: [],
                suggestedes: [],
              };
            });
        } else {
          this.loadingSearching = false;
          this.goSearchInput = false;
          clearInterval(this.searchInteralText);
          this.searchInteralText = undefined;
          intervalInput = false;
          this.whatResults = {
            requests: [],
            suggestedes: [],
          };
        }
      }, 500);
    }
  }

  selectRequest(index: number, req) {
    req.time = null;
    this.newRequest.requests.push(req);
    this.showTimeReq.push(false);
    this.whatResults.requests.splice(index, 1);
  }

  selectRequestSuggested(index: number, req) {
    this.newRequest.requests.push(
      Object.assign(this.whatResults.suggestedes[index], {
        time: null,
      })
    );
    this.showTimeReq.push(false);
    this.whatResults.suggestedes.splice(index, 1);
  }

  removeReq(index: number) {
    if (this.newRequest.requests[index].id) {
      this.whatResults.requests.push(this.newRequest.requests[index]);
    } else {
      this.whatResults.suggestedes.push(this.newRequest.requests[index]);
    }
    this.showTimeReq.splice(index, 1);
    this.newRequest.requests.splice(index, 1);
  }

  changeDate(i: number) {
    this.showTimeReq[i] = !this.showTimeReq[i];
  }

  onChangeDate(e) { }

  save() {
    this.loading = true;
    this.service
      .createNewGuestRequest(this.newRequest)
      .then(requests => {
        this.closeModal();
        this.openSnackBar(
          this.translate
            .instant(
              "Request has been successfully created. Request ID NAME"
            )
            .replace("NAME", requests.map(r => r.id).join(' ' + this.translate.instant("and") + ' ')),
          "✓"
        );
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
