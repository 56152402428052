import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../services/url-builder.service";
import { Auth0Service } from "../auth0.service";
import { environment } from "../../environments/environment";

@Injectable()
export class MyProfileService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  languages() {
    return this.http.get<any>(`${this.urlbuilder.subscriptionApi
      .concat('/languages')}`).toPromise();
  }

  getUserInformation() {
    return this.http.get<any>(`${this.urlbuilder.appApi
      .concat("/people-center/user/")
      .concat(
        this.auth.userProfile.id
      )}`).toPromise();
  }

  update(data) {
    data = Object.assign({}, data, { userId: this.auth.userProfile.id });
    return this.http.put<any>(`${this.urlbuilder.appApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/people-center/my-profile/")
      .concat(this.auth.userProfile.id)}`, data).toPromise();
  }

  updatePassword(data) {
    data = Object.assign({}, {
      userId: this.auth.userProfile.id,
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmNewPassword: data.repeatPassword
    });
    return this.http.put<any>(`${this.urlbuilder.appApi
      .concat("/people-center/user/password")}`, data).toPromise();
  }

  uploadImgs(data) {
    data.append('userId', this.auth.userProfile.id);
    return this.http.post<any>(`${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/${this.auth.getChosenProperty}`, data, {
      reportProgress: true,
      observe: 'events'
    });
  }

}
