import { Injectable } from "@angular/core";

import { environment } from "../../environments/environment";
import { format, subMinutes } from "date-fns";
/** Server Protocol string */
const PROTOCOL = environment.PROTOCOL;
/** Server Host string */
let HOST = environment.BACKENDAPP.HOST;
/** Server Domain string */
/* Retrieves the domain name from the current hostname URL */
const DOMAIN = environment.BACKENDAPP.DOMAIN;
/** Software Version string */

@Injectable()
export class UrlBuilderService {
  constructor() {}

  oauth = {
    token() {
      return environment.PROTOCOL.concat(environment.BACKENDOAUTH.HOST)
        .concat(environment.BACKENDOAUTH.DOMAIN)
        .concat("/token");
    },
    logout() {
      return environment.PROTOCOL.concat(environment.BACKENDOAUTH.HOST)
        .concat(environment.BACKENDOAUTH.DOMAIN)
        .concat("/logout");
    },
  };

  requests = {
    create() {
      return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/requests-new");
    },
    list: {
      actives() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/requests/active`
          );
      },
      finisheds(input: { fromDate: Date; toDate: Date }) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/requests/finished`
          )
          .concat(
            input.fromDate && input.toDate
              ? `?fromDate=${format(
                  input.fromDate,
                  "yyyy-MM-dd"
                )}&toDate=${format(input.toDate, "yyyy-MM-dd")}`
              : ""
          );
      },
      futures(input: { fromDate: Date; toDate: Date }) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(`/${localStorage.getItem("chosen_property")}/requests/future`)
          .concat(
            input.fromDate && input.toDate
              ? `?fromDate=${format(
                  input.fromDate,
                  "yyyy-MM-dd"
                )}&toDate=${format(input.toDate, "yyyy-MM-dd")}`
              : ""
          );
      },
      all(input: { fromDate: Date; toDate: Date }) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(`/${localStorage.getItem("chosen_property")}/requests`)
          .concat(
            input.fromDate && input.toDate
              ? `?fromDate=${format(
                  input.fromDate,
                  "yyyy-MM-dd"
                )}&toDate=${format(input.toDate, "yyyy-MM-dd")}`
              : ""
          );
      },
    },
    get(id: number) {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat(`/${localStorage.getItem("chosen_property")}/requests/${id}`);
    },
  };

  guestItems = {
    lostAndFound: {
      brands() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost-found/brands");
      },
      categories() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost-found/categories");
      },
      models() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost-found/models");
      },
    },
    lost: {
      create() {
        return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/guest-items/lost");
      },
      delete(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost/" + id);
      },
      list() {
        return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/guest-items/lost");
      },
      get(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost/" + id);
      },
      update(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost/" + id);
      },
      statuses() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/lost/statuses");
      },
      uploadPhoto() {
        return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/upload/photos");
      },
      deletePhoto(id: any) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/upload/photos/" + id);
      },
    },
    found: {
      create() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/found");
      },
      delete(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/found/" + id);
      },
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/found");
      },
      get(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/found/" + id);
      },
      update(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/found/" + id);
      },
      statuses() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/found/statuses");
      },
    },
    packages: {
      create() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/package");
      },
      delete(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/package/" + id);
      },
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/packages");
      },
      get(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/package/" + id);
      },
      update(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/package/" + id);
      },
      statuses() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/packages/statuses");
      },
      shipmentCia() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/guest-items/packages/shipment-cia");
      },
    },
  };

  requestConfiguration = {
    departments: {
      listPlain() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/process-builder/departments`
          );
      },
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/departments");
      },
      get(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/department/" + id);
      },
      create() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/department");
      },
      update(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/department/" + id);
      },
      delete(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/department/" + id);
      },
      enabledSuspended(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/department/" + id + "/enabled");
      },
    },
    categories: {
      list(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/process-builder/categories/byDepartment/${id}`
          );
      },
      get(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/category/" + id);
      },
      create() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/category");
      },
      update(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/category/" + id);
      },
      delete(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/category/" + id);
      },
      enabledSuspended(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/category/" + id + "/enabled");
      },
    },
    subcategories: {
      list(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/processes/byCategory/" + id);
      },
      get(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/process/" + id);
      },
      create() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/process");
      },
      update(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/process/" + id);
      },
      delete(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/process/" + id);
      },
      enabledSuspended(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/process-builder/process/" + id + "/enabled");
      },
    },
  };

  createDepartment() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/department");
  }
  createProcessCategory() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/category");
  }
  createProcess() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/process");
  }

  updateProcessCategory(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/category/" + id);
  }

  updateProcessDepartment(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/department/" + id);
  }
  updateProcess(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/process/" + id);
  }

  uploadPhoto() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/upload/photos");
  }

  deletePhoto(id: any) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/upload/photos/" + id);
  }

  deleteDriverById(id: any) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/drivers/" + id);
  }

  getDashboardMetricsStaff(input) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/" + input.propertyId + "/dashboard");
  }

  getSettingsWidgetsByUser(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        "/" +
          localStorage.getItem("chosen_property") +
          "/dashboard/widgets/" +
          id
      );
  }

  getSettingsWidgetsByUserStaff(input) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/" + input.propertyId + "/dashboard/widgets/" + input.userId);
  }
  /** Method concatenates strings to get Get All Request URL
   * @returns Get All Request URL
   */
  get_all_suggest() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        "/" + localStorage.getItem("chosen_property") + "/requests-suggestedes"
      );
  }

  get_most_requestedUserId(id: number, dep: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/my-hub/requests/user/" + id + "/department/" + dep);
  }
  get_most_requesteddepId(dep: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/my-hub/requests/department/" + dep);
  }

  getFUQbyProcess(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/processBuilder/getFollowUpQuestionsByProcess/" + id);
  }

  getProcessByID(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/process/" + id);
  }

  getAllTicketsByRequestId(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/requests/process/" + id);
  }

  getAllTicketStatuses() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/ticket-manager/statuses");
  }

  getAllTicketPriorities() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/ticket-manager/priorities");
  }

  updateTicketById(ticketId: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/ticket-manager/ticket/" + ticketId);
  }

  getDeps(id: number) {
    if (id === 1) {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/process-builder/departments");
    } else {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/process-builder/categories");
    }
  }

  departmentChangeEnabled(id: number, enable: boolean) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/department/changeEnabled/" + id + "/" + enable);
  }

  categoryChangeEnabled(id: number, enable: boolean) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/category/changeEnabled/" + id + "/" + enable);
  }

  processChangeEnabled(id: number, enable: boolean) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)

      .concat("/process-builder/process/changeEnabled/" + id + "/" + enable);
  }

  getRuleAllDepartamentsCategoriesAndSubcategoriesByID(id: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/departmentsCategoriesProcesses/" + id);
  }

  getAllAvailableShuttles() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/getAllAvailableShuttles");
  }

  getAllAvailablePickups() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(
        `/${localStorage.getItem("chosen_property")}/reservations/actives`
      );
  }

  getHistoryPickups() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(
        `/${localStorage.getItem("chosen_property")}/reservations/archives`
      );
  }

  getNearbyAirports() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/airports");
  }

  getVehicleById(vehicleId: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/vehicles/" + vehicleId);
  }

  removeReservationById(id: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(`/${localStorage.getItem("chosen_property")}/reservations/` + id);
  }

  getReservationsByCode(pickupId: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(
        `/${localStorage.getItem("chosen_property")}/reservations/code/` +
          pickupId
      );
  }

  createReservation() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(`/${localStorage.getItem("chosen_property")}/reservations`);
  }

  getReservationsById(pickupId: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(
        `/${localStorage.getItem("chosen_property")}/reservations/` + pickupId
      );
  }

  exportsAllDepartmentsCategoriesAndSubcategories() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/exports");
  }

  importsAllDepartmentsCategoriesAndSubcategories() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/imports");
  }

  updateReservationById(reservationId: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(
        `/${localStorage.getItem("chosen_property")}/reservations/` +
          reservationId
      );
  }

  // obtener las reglas de una categoria

  getRuleCategeryByID(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/rules-manager/rule/assignToSubcategory/" + id);
  }

  // setear las reglas de una Categoria

  RulesubCategeryByID(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/rules-manager/rule/setToProcessCategory/" + id);
  }

  // setear las reglas de una Subcategories

  setRulesubCategeryByID(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/rules-manager/rule/setToProcessSubcategory/" + id);
  }

  // agregar departamento,categoria,Subcategories apartir de regla
  postRuleDepartamentsByID(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        "/rules-manager/rule/assignProcessDepartmentProcessCategoryAndSubcategory/" +
          id
      );
  }

  deleteProcessCategory(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/category/" + id);
  }

  deleteDepartCategory(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/department/" + id);
  }

  deleteProcessSubcategory(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/process/" + id);
  }

  //Subcategorieshg
  deleteSuggestedSubcategoryById(name) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        "/" +
          localStorage.getItem("chosen_property") +
          "/requests-suggested/" +
          name
      );
  }

  mergeSuggestedSubcategory() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        "/" + localStorage.getItem("chosen_property") + "/requests-suggested"
      );
  }

  deletePhotoObjectById(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/upload/photos/" + id);
  }

  getDepsById(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/processBuilder/getProcessCategoriesById/" + id);
  }

  getCategoriesByDep(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        `/${localStorage.getItem(
          "chosen_property"
        )}/process-builder/categories/byDepartment/` + id
      );
  }
  getAllRulesAssignToDepartmentOrCategory(level: number, id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        "/rules-manager/rules/getAllRulesAssignToDepartmentOrCategory/" +
          level +
          "/" +
          id
      );
  }

  getProcessCategory(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/category/" + id);
  }

  getProcessDepartment(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/department/" + id);
  }

  getProcessesByCategory(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/processes/byCategory/" + id);
  }

  getProcessById(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/process/" + id);
  }

  get_Process_Type_IdByName() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/processBuilder/getProcessTypeIdByName");
  }

  getAllPermissions() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/permissions");
  }

  createRole() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/people-center/role");
  }

  updateRole(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/role/" + id);
  }

  getRoleByName(name) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/role/name/" + name);
  }

  getAllRoles() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/people-center/roles");
  }

  getRole(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/role/" + id);
  }

  bulkAssignPermissionsToRole() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/role/assignPermissions");
  }

  getPermissionsByRoleId(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/permissions/" + id);
  }

  getAllUsertypes() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertypes");
  }

  createUsertype() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype");
  }

  deleteUsertype(id: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype/" + id);
  }

  getUsertypeByName(name) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype/name/" + name);
  }

  getUsertype(id: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype/" + id);
  }

  getRolesByUsertypeId(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/role/usertype/" + id);
  }

  bulkAssignRolesToUsertype() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype/assignRoles");
  }

  updateUsertype(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype/" + id);
  }

  removeUsertypeById(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/usertype/" + id);
  }

  getAllUsers() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/people-center/users");
  }

  getAllUsersRequest(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/users/getAllUsers?inDepartment=" + id);
  }

  getAllUsersByDepartmentId(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/users/getAllUsersByDepartmentId/" + id);
  }

  getUser(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/user/" + id);
  }

  getWidgtes() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/dashboard/widgets");
  }

  updateWidgets() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/dashboard/widgets");
  }

  searchRequestType(q: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/search-request-type/" + q);
  }

  updateUserPassword(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/user/password/" + id);
  }

  updateUserPasswordCurrent(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/user/password");
  }

  inviteUser() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/people-center/user");
  }

  resetUserPassword() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/users/requestUserPasswordReset");
  }

  removeUserByIdTenand(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/user/" + id);
  }

  deleteRoleId(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/role/" + id);
  }

  getTicketComments(ticketId: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/ticket-manager/ticket/" + ticketId + "/comments");
  }

  getTicketHistory(ticketId: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/ticket-manager/ticket/" + ticketId + "/history");
  }

  postTicketComment(ticketId) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/ticket-manager/ticket/" + ticketId + "/comments");
  }

  createLocation() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/locations");
  }

  listMessages() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/statuses");
  }

  listLocations() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/locations");
  }

  updateMessages() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/statuses");
  }

  updateLocationById(id) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/locations/" + id);
  }

  getGuesthubPermissions() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/permissions");
  }

  uploadSignature() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/upload/signature");
  }

  uploadObjectPhoto() {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat("/upload/photos");
  }

  dashboardRequestCounterInternal() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/request-counter/internal");
  }

  dashboardRequestCounterIssues() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/request-counter/guest/issues");
  }

  dashboardRequestCounterAmenities() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/request-counter/guest/amenities");
  }

  dashboardRequestsSpecialRequests() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/daily-behavior/requests-special-requests");
  }

  dashboardRoomSoldRequests() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/daily-behavior/room-sold-requests");
  }

  dashboardRequestCounterOverall() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/request-counter/overall");
  }

  dashboardRoomsSoldKpIsGuestsRequests() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/room-sold-kpis/guests-requests");
  }

  dashboardRoomsSoldKpIsInternalRequests() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/room-sold-kpis/internal-requests");
  }

  dashboardRoomsSoldKpIsOverallRequests() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/room-sold-kpis/overall-requests");
  }

  dashboardRoomsSoldKpIsRoomSoldCount() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(`/dashboard/room-sold-kpisd/roomsold-count`);
  }

  dashboardRequestsKpIs() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/request-kpis");
  }

  dashboardRequestsKpIsInterGuest() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/inter-vs-guest");
  }

  dashboardRequestsKpIsRequestExcution() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/request-execution");
  }

  dashboardRequestsKpIsRuleExecution() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/rule-execute");
  }

  dashboardRequestsKpIsTask() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/dashboard/task-execution");
  }

  dashboard() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(`/${localStorage.getItem("chosen_property")}/dashboard`);
  }

  efficiencyUser() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/staff-hub/efficiency-user");
  }

  efficiencySubcategoryLeadlagTime() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/staff-hub/efficiency-subcategory-leadlag-time");
  }

  efficiencySubcategoryOnHoldTime() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/staff-hub/efficiency-subcategory-onhold-time");
  }

  staffHubClosedTasksUser() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/staff-hub/closed-tasks-user");
  }

  getAllGuest() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/guest/in-house");
  }

  getAllGuestUsers() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/guest/in-house/users");
  }

  getGuestById(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/guest/" + id);
  }

  getAllRequestsByGuestId(guestId: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/requests/process/guests/" + guestId);
  }

  updatePropertyById(id: number) {
    return PROTOCOL.concat(environment.BACKENDOSUBSCRIPTION.HOST)
      .concat(environment.BACKENDOSUBSCRIPTION.DOMAIN)
      .concat("/property/" + id);
  }

  getPropertyById(id: number) {
    return PROTOCOL.concat(environment.BACKENDOSUBSCRIPTION.HOST)
      .concat(environment.BACKENDOSUBSCRIPTION.DOMAIN)
      .concat("/property/" + id);
  }

  removePropertyById(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/property/" + id);
  }

  addUserSubscriptionAdmin(id: number) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/user/subscriptionadmin/" + id);
  }

  removeUserSubscriptionAdmin(id) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/user/subscriptionadmin/" + id);
  }

  getAllUsersSubscription() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/users/subscriptionUsers");
  }

  getAllCountries() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/countries");
  }

  getAllTimeZones() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/timezones");
  }

  getAllLanguages() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/people-center/languages");
  }

  removeVehicleById(id: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/vehicles/" + id);
  }

  deleteLocationById(id: number) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/locations/" + id);
  }

  createVehicle() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/vehicles");
  }

  createDriver() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/drivers");
  }

  getDriverByEmail(email: any) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/drivers/" + email);
  }

  updateVehicleById(vehicleId: any) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/vehicles/" + vehicleId);
  }

  updateDriverEmail(email: any) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/drivers/" + email);
  }

  getPickUpReservationsByGuestId(guestId: any) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/reservations/guest/" + guestId);
  }

  getAllDrivers() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/drivers");
  }

  getAllVehicles() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/vehicles");
  }

  getAllTransports() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/transports");
  }

  deleteTransport(transport: any): string {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/transports")
      .concat(`/${transport.id}`);
  }

  transportDetails(transportId) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(`/pickup/transports/${transportId}`);
  }
  getAirportsByProperty() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat("/pickup/airports/different");
  }

  getDistanceBtwnTwoPoints(data) {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(
        `/pickup/distance?originLatitude=${data.originLatitude}&originLongitude=${data.originLongitude}&destinationLatitude=${data.destinationLatitude}&destinationLongitude=${data.destinationLongitude}&key=${data.key}`
      );
  }

  getAllOtherLocationsAndRooms() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/requests/process/locations");
  }

  listLocationsEvents() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(`/${localStorage.getItem("chosen_property")}/requests/locations`);
  }

  setCustomUrl(endpoint) {
    return PROTOCOL.concat(HOST).concat(DOMAIN).concat(endpoint);
  }

  resendMagicLink() {
    return PROTOCOL.concat(environment.BACKENDPICKUP.HOST)
      .concat(environment.BACKENDPICKUP.DOMAIN)
      .concat(`/${localStorage.getItem("chosen_property")}/pickup/magic-links`);
  }
  contextByEmail() {
    return PROTOCOL.concat(environment.BACKENDOAUTH.HOST)
      .concat(environment.BACKENDOAUTH.DOMAIN)
      .concat("/authorization/login");
  }

  publicArea = {
    list() {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/settings/public-areas");
    },
    get(id: string) {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/settings/public-areas/" + id);
    },
    create() {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/settings/public-areas");
    },
    update(id) {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/settings/public-areas/" + id);
    },
    delete(id) {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat("/settings/public-areas/" + id);
    },
  };

  notifications = {
    list() {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat(`/${localStorage.getItem("chosen_property")}/notifications`);
    },
    markToRead(id) {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat(
          `/${localStorage.getItem("chosen_property")}/notification/${id}`
        );
    },
    deleteAll() {
      return PROTOCOL.concat(HOST)
        .concat(DOMAIN)
        .concat(`/${localStorage.getItem("chosen_property")}/notifications`);
    },
  };

  dashboardApi = PROTOCOL.concat(environment.BACKENDDASHBOARD.HOST).concat(
    environment.BACKENDDASHBOARD.DOMAIN
  );
  requestsApi = PROTOCOL.concat(environment.BACKENDREQUESTS.HOST).concat(
    environment.BACKENDREQUESTS.DOMAIN
  );
  propertyApi = PROTOCOL.concat(environment.BACKENDPROPERTY.HOST).concat(
    environment.BACKENDPROPERTY.DOMAIN
  );
  checkInApi = PROTOCOL.concat(environment.BACKENDCHECKIN.HOST).concat(
    environment.BACKENDCHECKIN.DOMAIN
  );
  tasksManagerApi = PROTOCOL.concat(
    environment.BACKENDTASKSMANAGER.HOST
  ).concat(environment.BACKENDTASKSMANAGER.DOMAIN);
  communicationApi = PROTOCOL.concat(
    environment.BACKENDCOMMUNICATIONS.HOST
  ).concat(environment.BACKENDCOMMUNICATIONS.DOMAIN);
  guestItemsApi = PROTOCOL.concat(environment.BACKENDGUESTITEMS.HOST).concat(
    environment.BACKENDGUESTITEMS.DOMAIN
  );
  outletsApi = PROTOCOL.concat(environment.BACKENDOUTLETS.HOST).concat(
    environment.BACKENDOUTLETS.DOMAIN
  );
  appApi = PROTOCOL.concat(environment.BACKENDAPP.HOST).concat(
    environment.BACKENDAPP.DOMAIN
  );
  reservationsApi = PROTOCOL.concat(
    environment.BACKENDORESERVATIONS.HOST
  ).concat(environment.BACKENDORESERVATIONS.DOMAIN);
  guestappApi = PROTOCOL.concat(environment.BACKENDOGUESTAPP.HOST).concat(
    environment.BACKENDOGUESTAPP.DOMAIN
  );
  subscriptionApi = PROTOCOL.concat(
    environment.BACKENDOSUBSCRIPTION.HOST
  ).concat(environment.BACKENDOSUBSCRIPTION.DOMAIN);
  communicationsApi = PROTOCOL.concat(
    environment.BACKENDOCOMMUNICATIONS.HOST
  ).concat(environment.BACKENDOCOMMUNICATIONS.DOMAIN);

  guestApp = {
    deleteServiceById(propertyId, id) {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(`/properties/${propertyId}/guestapp-feature/${id}`);
    },
    getServiceById(propertyId, id) {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(`/properties/${propertyId}/guestapp-feature/${id}`);
    },
    getImages(id) {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(`/properties/${id}/guestapp-features-images`);
    },
    updateTitle(propertyId, id) {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(`/properties/${propertyId}/guestapp-feature/${id}`);
    },
    newTitle(id) {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(`/properties/${id}/guestapp-features`);
    },
    generalQr() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat("/qr");
    },
    getServices() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          "/properties/" +
            localStorage.getItem("chosen_property") +
            "/features?hidden=true"
        );
    },
    updateServices() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          "/properties/" + localStorage.getItem("chosen_property") + "/features"
        );
    },
    getSettings() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          "/properties/" +
            localStorage.getItem("chosen_property") +
            "/guestapp-settings"
        );
    },
    updateSettings() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          "/properties/" +
            localStorage.getItem("chosen_property") +
            "/guestapp-settings"
        );
    },
    updateOrDeactivateEmailTemplate(type: string) {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          `/properties/${localStorage.getItem(
            "chosen_property"
          )}/settings/${type}`
        );
    },
    updateEmailTemplate() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          `/properties/${localStorage.getItem("chosen_property")}/settings`
        );
    },
    getEmailTemplate() {
      return PROTOCOL.concat(environment.BACKENDORESERVATIONS.HOST)
        .concat(environment.BACKENDORESERVATIONS.DOMAIN)
        .concat(
          `/properties/${localStorage.getItem("chosen_property")}/settings`
        );
    },
    getStatisticTemplate(from: any, to: any) {
      return PROTOCOL.concat(environment.BACKENDOGUESTAPP.HOST)
        .concat(environment.BACKENDOGUESTAPP.DOMAIN)
        .concat("/")
        .concat(localStorage.getItem("chosen_property"))
        .concat(
          `/statistics/metrics?fromDate=${new Date(
            from
          ).toISOString()}&toDate=${new Date(to).toISOString()}`
        );
    },
    getGuestInHouse() {
      return PROTOCOL.concat(HOST).concat(DOMAIN).concat(`/guests/in-house`);
    },
    postSendEmailStatistic(from: any, to: any) {
      return PROTOCOL.concat(environment.BACKENDOGUESTAPP.HOST)
        .concat(environment.BACKENDOGUESTAPP.DOMAIN)
        .concat("/")
        .concat(localStorage.getItem("chosen_property"))
        .concat(
          `/statistics/metrics?fromDate=${new Date(
            from
          ).toISOString()}&toDate=${new Date(to).toISOString()}`
        );
    },
  };

  rulesManager = {
    list() {
      return environment.BACKENDORULESMANAGER.url.concat("/rules");
    },
    get(id: string) {
      return environment.BACKENDORULESMANAGER.url
        .concat("/rule")
        .concat("/")
        .concat(id);
    },
    create() {
      return environment.BACKENDORULESMANAGER.url.concat("/rule");
    },
    update(id) {
      return environment.BACKENDORULESMANAGER.url
        .concat("/rule")
        .concat("/")
        .concat(id);
    },
    enabledDisabled(defaults, enabled) {
      return environment.BACKENDORULESMANAGER.url
        .concat("/rule")
        .concat("/")
        .concat(defaults)
        .concat("/")
        .concat(enabled);
    },
    delete(id) {
      return environment.BACKENDORULESMANAGER.url
        .concat("/rule")
        .concat("/")
        .concat(id);
    },
  };

  settings() {
    return {
      getAllModules() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/properties/${localStorage.getItem(
              "chosen_property"
            )}/settings/features`
          );
      },
      enabledDisabledPermision(name: string, enabled: boolean) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/module/" + enabled + "/" + name);
      },
      changeDefaultModule(codename: string, defaul: boolean) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/module/" + defaul + "/" + codename + "/default");
      },
      enabledDisabledPatchModule(
        codename: string,
        enabled: boolean,
        patch: string
      ) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/module/" + enabled + "/" + codename + "/" + patch);
      },
      updateUsersOnline(userId: number, online: boolean, propertyId: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            "people-center/user/online/" +
              userId +
              "/" +
              online +
              "/" +
              propertyId
          );
      },
      getEmailTemplateByID(emailTemplateID: string) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("settings/email-template/" + emailTemplateID);
      },
      getAllEmailTemplates() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/email-templates");
      },
      updateEmailTemplateByID(emailTemplateID: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("settings/email-template/" + emailTemplateID);
      },
      deleteEmailTemplateByID(emailTemplateID: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("settings/email-template/" + emailTemplateID);
      },
      cloneEmailTemplateByID(emailTemplateID: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("settings/email-template/" + emailTemplateID);
      },
      createEmailTemplateByID() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("settings/email-template");
      },
      getSendEmails(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/settings/reports/sendEmails?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getDetailLifelineS() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-users-online");
      },
      getConditionLifelineS() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-users-online/conditions");
      },
      getEventLifelineS() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-users-online/events");
      },
      settLifelineS() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-users-online");
      },
      getDetailLifelineSPick() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-pickup-driver-online");
      },
      getConditionLifelineSPick() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-pickup-driver-online/conditions");
      },
      getEventLifelineSPick() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-pickup-driver-online/events");
      },
      settLifelineSPick() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/settings/notifications-pickup-driver-online");
      },
      getEmailLog(id, data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            `${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/${id}/traking?fromDate=${data.fromDate.setHours(
              0,
              0,
              0,
              0
            )}&toDate=${data.toDate.setHours(23, 59, 59, 999)}`
          );
      },
      getEmailLogPage(id, page, data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            `${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/${id}/traking?page=${page}&fromDate=${data.fromDate.setHours(
              0,
              0,
              0,
              0
            )}&toDate=${data.toDate.setHours(23, 59, 59, 999)}`
          );
      },
      getReviewLog(id, data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/${id}/results?fromDate=${format(
              data.date[0],
              "yyyy-MM-dd"
            )}&toDate=${format(data.date[1], "yyyy-MM-dd")}`
          );
      },
      postReviewResponse() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/result/response`
          );
      },
      postMessageLog(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/messages/` +
              id +
              "/re-send"
          );
      },
    };
  }
  EventsManager() {
    return {
      getAllEventsRules() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/event-manager/rules");
      },
      getAllEventsRulesStatuses() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/event-manager/rules/statuses");
      },
      getAllEventsOpera() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/event-manager/opera");
      },
      getAllEventsReports() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/event-manager/reports");
      },
      getAllEventsReviews() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/event-manager/reviews");
      },
    };
  }

  Communications() {
    return {
      sendDailySurveyReports() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/surveys/reports");
      },
      getConditionsFlow() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/flows/conditions");
      },
      getListFlow() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/flows/events");
      },
      listFlows() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/flows");
      },
      deleteFlows(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}communications/setting/flow/${id}`
          );
      },
      selectedMessage(flowId: number, reservationId: number, email: string) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/messages/selected/" +
              flowId +
              "/" +
              reservationId +
              "/" +
              email
          );
      },
      SendLinkGuests(id: any) {
        return PROTOCOL.concat(environment.BACKENDAPP.HOST)
          .concat(environment.BACKENDAPP.DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/workflows/${id}/manual`
          );
      },
      selectedSurvey(flowId: number, reservationId: number, email: string) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/reviews/selected/" +
              flowId +
              "/" +
              reservationId +
              "/" +
              email
          );
      },
      updateFlows(id: number) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/flow/" + id);
      },
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/reviews");
      },
      listReviewsDateRange(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/reviews?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getSentVsOpened(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/reports/sent-vs-opened?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getReportMonthly(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/reports/monthly?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getSendEmails(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/reports/sendEmails?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },

      getUndeliveredmessages(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/messages/undeliveredMessages?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getUndeliveredEmails(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/reports/undeliveredEmails?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      totalFeedback(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/feedback/dataRange?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      totalLikedAndRecomendations(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/feedback/per-month?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getSettingsReports() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting");
      },
      updateOrCreateReportsSettings() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting");
      },
      getReportsListUser() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/users-notifications");
      },
      updateReportsListUser() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/users-notifications");
      },
      emailByStatuses(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "/communications/emails/statuses?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      createReview() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/review");
      },
      getEmailsStatus(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            "communications/emails/status?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getDashboardSentEmails(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            "communications/emails/dataRange?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getReportTotals(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            "communications/reports/totals?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getAllSocialMedia() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("communications/setting/social-media");
      },
      getAllSocialMediaInProperty() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("communications/setting/social-media-in-property");
      },
      updateSocialMediaByID() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("communications/setting/social-media-in-property");
      },
      sendEmailReports(reportType) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat("communications/reports/send-reports/" + reportType);
      },
      getmessagesTotals(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            "communications/messages/panel/totalMessages?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getmessagesMontly(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            "communications/messages/panel/montlyMessages?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      getmessagesSentVsOpened(data) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/")
          .concat(
            "communications/messages/panel/sent-vs-opened?fromDate=" +
              data.fromDate +
              "&toDate=" +
              data.toDate
          );
      },
      createFlow() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/flow");
      },
      getFlow(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/setting/flow/" + id);
      },
      getAllTemplateEmails() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat("/communications/settings/email-templates");
      },
    };
  }
  CommunicationsWorkflowNotifications() {
    return {
      update(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/workflows/${id}/notifications`
          );
      },
      get(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/workflows/${id}/notifications`
          );
      },
    };
  }

  CommunicationsSurvey() {
    return {
      createNew() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/communications/surveys`
          );
      },
      update(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/${id}`
          );
      },
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/communications/surveys`
          );
      },
      get(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/${id}`
          );
      },
      createNewSendCSV() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/surveys/send`
          );
      },
      updateSocialMediaObj() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/socialMedia`
          );
      },
      listSocialMediaObj() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/socialMedia`
          );
      },
      EmailTemplate() {
        return {
          createNew() {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email`
              );
          },
          update(id) {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/${id}`
              );
          },
          list() {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email`
              );
          },
          get(id) {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/${id}`
              );
          },
          createNewSendCSV() {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/send`
              );
          },
          getEmailLog(id, data) {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat("/")
              .concat(
                `${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/${id}/traking?fromDate=${data.fromDate.setHours(
                  0,
                  0,
                  0,
                  0
                )}&toDate=${data.toDate.setHours(23, 59, 59, 999)}`
              );
          },
          uploadPhoto() {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat("/communications/messages/photo");
          },
          getEmailName(name) {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/template/${name}`
              );
          },
          delete(id) {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/${id}`
              );
          },
          postTemplateManual() {
            return PROTOCOL.concat(HOST)
              .concat(DOMAIN)
              .concat(
                `/${localStorage.getItem(
                  "chosen_property"
                )}/communications/workflows/email/sendReport`
              );
          },
        };
      },
    };
  }
  Companies() {
    return {
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/companies`
          );
      },
      get(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/companies/${id}`
          );
      },
      post() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/companies`
          );
      },
      put(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/companies/${id}`
          );
      },
      delete(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/companies/${id}`
          );
      },
    };
  }
  RateCodes() {
    return {
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/rate-codes`
          );
      },
      get(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/rate-codes/${id}`
          );
      },
      post() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/rate-codes`
          );
      },
      put(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/rate-codes/${id}`
          );
      },
      delete(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/rate-codes/${id}`
          );
      },
    };
  }
  VipCodes() {
    return {
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/vip-codes`
          );
      },
      get(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/vip-codes/${id}`
          );
      },
      post() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/vip-codes`
          );
      },
      put(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/vip-codes/${id}`
          );
      },
      delete(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/vip-codes/${id}`
          );
      },
    };
  }
  Groups() {
    return {
      list() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/communications/groups`
          );
      },
      get(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/groups/${id}`
          );
      },
      post() {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem("chosen_property")}/communications/groups`
          );
      },
      put(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/groups/${id}`
          );
      },
      delete(id) {
        return PROTOCOL.concat(HOST)
          .concat(DOMAIN)
          .concat(
            `/${localStorage.getItem(
              "chosen_property"
            )}/communications/groups/${id}`
          );
      },
    };
  }

  setGetUserPreferences(userId: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(
        `/${localStorage.getItem(
          "chosen_property"
        )}/people-center/user/${userId}/preferences`
      );
  }

  setLifeline(user, online, tenat) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat(`/people-center/user/online/${user}/${online}/${tenat}`);
  }
  searchDep(e: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/department/search/" + e);
  }
  searchCat(e: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/category/search/" + e);
  }
  searchSubCat(e: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/process-builder/processes/search/" + e);
  }
  uploadPhotoContainer(e: string) {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/file-manager/upload/" + e);
  }
  uploadFileContainer() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .concat("/file-manager/upload/file/guest-app");
  }
  dashboardReport() {
    return PROTOCOL.concat(HOST)
      .concat(DOMAIN)
      .replace("app-api.", "dashboard.");
  }
}
