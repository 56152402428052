import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetPermissionsService } from './get-permissions.service';

@Injectable()
export class GuesthubContextService {
    router: any = null;
    private currentProperties: any;
    private guesthubPermissions: any;

    infoSession: any;
    property: string;

    constructor(
        router: Router,
        private getPermissionsService: GetPermissionsService
    ) {
        this.router = router;
    }

    getContext(): any {

        return localStorage.getItem('guesthub_context') ? JSON.parse(localStorage.getItem('guesthub_context')) : {properties:[]};
    }

    getAvailableProperties(): any {
        return this.getContext().properties;
    }

    getCurrentProperties(): any {
        if (this.currentProperties) {
            return this.currentProperties;
        } else {
            if (localStorage.getItem('chosen_property') === null) {
                return [0]; //AG set property 0 by default, allows for generic requests in the API, like getAllPermissions
            } else {
                return [JSON.parse(localStorage.getItem('chosen_property'))];
            }
        }
    }

    setCurrentProperties(properties): any {
        this.currentProperties = properties;
    }

    setGuesthubPermissions(permissions): any {
        this.guesthubPermissions = permissions;
    }

    chooseProperty(property, goHome: boolean = false): any {
        let isLogin: boolean = false;
        if (!localStorage.getItem('chosen_property')) {
            isLogin = true;
        }
        this.infoSession = JSON.parse(localStorage.getItem('guesthub_context'))
        this.property = localStorage.getItem('chosen_property')
        if (this.property){
          this.getPermissionsService.setNotifiLineline(this.property, false, this.infoSession.userId).then(resp => resp)
        }
        const actualProperty = localStorage.getItem('chosen_property')
        if (goHome) {
            this.router.navigate(['/']);
        }
        this.setCurrentProperties([property]);
        localStorage.setItem('chosen_property', property.toString());
        const chosenProperty: any = this.getAvailableProperties().find((_property: any) => _property.id === property);
        localStorage.setItem('scopes', JSON.stringify(chosenProperty.permissions));
        if (actualProperty != property) {
            this.infoSession = JSON.parse(localStorage.getItem('guesthub_context'))
            this.property = localStorage.getItem('chosen_property')
            if (this.property){
              this.getPermissionsService.setNotifiLineline(this.property, true, this.infoSession.userId).then(resp => resp)
            }
            if (!isLogin) location.reload();
        }
    }

    chooseDefaultProperty(): any {
        this.chooseProperty(this.getAvailableProperties()[0].id);
    }

    getCurrentPropertiesNames(): any {
        const currentProperties: any =
            this.getAvailableProperties()
                .filter((property: any) => this.getCurrentProperties().find((currentProperty: any) => currentProperty === property.id))

        let propertyNames: any = currentProperties.map((property: any) => property.name);
        propertyNames = propertyNames.length > 0 ? propertyNames.reduce((names, name) => names ? names + ', ' + name : name) : "";
        return propertyNames;
    }
}
