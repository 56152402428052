import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { Router } from "@angular/router";
import { RequestsManagerService } from "../../service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-requests-preview-guest-information",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit {
  @Output() output = new EventEmitter<boolean>();
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Input() request;
  reservation: any;
  loading: boolean = true;

  constructor(
    public router: Router,
    private service: RequestsManagerService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.loading = true;
    Promise.all([
      this.service.getReservationById(this.request.reservationId),
    ]).then(([reservation]) => {
      console.log(reservation)
      this.reservation = reservation;

      this.loading = false;
    });
  }

  closeModal() {
    this.output.emit(true);
  }

  goBack() {
    this.cancelEvent.emit(true);
  }

}
