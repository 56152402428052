import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoutingModule } from './routing.module';

import { RedirectToComponent } from './component';


@NgModule({
    declarations: [
      RedirectToComponent
    ],
    imports: [
        CommonModule,
        RoutingModule
    ],
    providers: [
    ]
})
export class RedirectToModule { }
