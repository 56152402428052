<div class="modal-content text-inicial white">
  <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob" style="margin-bottom: 22px">
    <i class="material-icons left pointer color-base-two" (click)="goBack()">arrow_back</i>
  </div>

  <div *ngIf="!loading">
    <div *ngIf="!reservation" class="col s12 m12 l12 xl12 padding40 modal-padding-leftright">
      <app-guesthub-component-monitor-no-result></app-guesthub-component-monitor-no-result>
    </div>
    <div *ngIf="reservation" class="col s12 m12 l12 xl12 padding40 modal-padding-leftright">
      <div class="col s12 l12 xl12 padding0">
        <span class="color-base font24 semiblod">
          {{ reservation['guest.firstName'] }} {{
          reservation['guest.lastName']}}
        </span>
      </div>

      <div class="scrolling-gavete" style="width: 100%">
        <div class="cols 9" style="width: 80%">
          <div class="col s12">
            <span class="text-left">
              <strong style="font-family: 'Open Sans', sans-serif !important">{{ "Confirmation" | translate }}
              </strong>
            </span>
            <span class="text-right" style="font-family: 'Open Sans', sans-serif !important;">
              <strong> #{{reservation['info.confirmation']}}</strong>
            </span>
          </div>
          <div class="col s12">
            <span class="text-left" style="
                font-family: 'Open Sans', sans-serif !important;
                color: #979797 !important;
              ">{{ "Company" | translate }}</span>
            <span class="text-right" style="
                font-family: 'Open Sans', sans-serif !important;
                color: #686c6d !important;
              ">{{ reservation["info.companyName"] }}</span>
          </div>

          <div class="col s12">
            <span style="
                font-family: 'Open Sans', sans-serif !important;
                color: #979797 !important;
              ">{{ "Language" | translate }}</span>
            <span class="text-right" style="
                font-family: 'Open Sans', sans-serif !important;
                color: #686c6d !important;
              ">...</span>
          </div>

          <div class="col s12">
            <br />
            <span class="text-left" style="font-family: 'Open Sans', sans-serif !important;">{{ "Status" | translate
              }}</span>
            <span class="btn btn-small btn-info text-right" style="font-size: 10px; border-radius: 3px !important">
              {{ reservation["info.status"] | translate }}
            </span>
            <br />
            <br />
          </div>
        </div>

        <div class="row white">
          <div class="col s12" style="border-top: solid 1px #eee"></div>
          <div class="detai col s12 white" style="
              font-family: 'Open Sans', sans-serif !important;
              padding-left: 0px;
              padding-right: 0px;
            ">
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l8 xl8 nopadding">
                <span>{{ "Room Number" | translate }}</span>
              </div>
              <div class="col s12 m12 l4 xl4 nopadding">
                <span class="text-right">{{ reservation["info.numRoom"]}}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Room Type" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.roomType"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Arrival Date" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.arrivalDate"] | date:
                  'mediumDate'}}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Departure Date" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.departureDate"] | date: 'mediumDate'
                  }}</span>
              </div>
            </div>

            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Title" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.title"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Phone number" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["guest.phone"]}}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Email" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span style="float: right">{{ reservation["guest.email"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Adults" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span style="float: right">{{ reservation["info.adults"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Children" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span style="float: right">{{ reservation["info.children"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Vip Code" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.vipCode"]}}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Vip Description" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.vipCodeDescription"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Group Name" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{reservation["info.groupName"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Group Id" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{reservation["info.groupId"] }}</span>
              </div>
            </div>
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Rate code" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.rateCode"]}}</span>
              </div>
            </div>
            <!-- <div class="col s12 stripedd-list padding15">
                        <div class="col s12 m12 l6 xl6 nopadding">
                            <span>{{ "Rate Amount" | translate }}</span>
                        </div>
                        <div class="col s12 m12 l6 xl6 nopadding">
                            <span class="text-right">{{ reservation["info.rateAmount"] }}</span>
                        </div>
                    </div> -->
            <div class="col s12 stripedd-list padding15">
              <div class="col s12 m12 l6 xl6 nopadding">
                <span>{{ "Creation Date" | translate }}</span>
              </div>
              <div class="col s12 m12 l6 xl6 nopadding">
                <span class="text-right">{{ reservation["info.creationDate"] | date: 'mediumDate':
                  '+0500' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="loadingdiv col s12 m12 xl12 center" *ngIf="loading">
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-green-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
</div>