import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-guesthub-component-loading',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
  @Input() type: string = 'default';

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }
}