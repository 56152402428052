import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../services/url-builder.service";
import { Subject } from 'rxjs';
import { FeaturesService } from "../../features.service";
import { Auth0Service } from "../../auth0.service";

@Injectable()
export class TopService {
  protected pictureObservable = new Subject<any>()
  activeSound$ = new EventEmitter<boolean>();
  refreshUserSoundPreferences$ = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private featureService: FeaturesService,
    private auth: Auth0Service
  ) { }

  setGetUserPreferences(userId, data) {
    return this.http.put<any>(this.urlbuilder.setGetUserPreferences(userId), data).toPromise();
  }

  languages() {
    return this.http.get<any>(this.urlbuilder.getAllLanguages()).toPromise();
  }

  getAllModules() {
    return this.featureService.getAllModules();
  }

  public nextPicture(item: any) {
    this.pictureObservable.next(item);
  }

  public subscribePicture(callback: (item: any) => void) {
    return this.pictureObservable.subscribe(callback);
  }

  sendCommunicationsReport() {
    return this.http.post<any>(this.urlbuilder.Communications().sendDailySurveyReports(), { email: this.auth.userProfile.email }).toPromise();
  }
}
