import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-guesthub-component-modal-disconnect',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {}
}