import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Auth0Service } from "../auth0.service";

@Injectable()
export class GuesthubInterceptor implements HttpInterceptor {
  constructor(private auth: Auth0Service) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.isAuthenticated) {
      const guesthubReq = req.clone({
        headers: req.headers.set(
          "Guesthub-Context",
          JSON.stringify({
            properties: [this.auth.getChosenProperty],
            user: this.auth.userProfile.id,
          })
        ),
      });
      const AuthHeader = guesthubReq.clone({
        headers: guesthubReq.headers.set(
          "Authorization",
          `Bearer ${this.auth.accessToken}`
        ),
      });
      const languageReq = AuthHeader.clone({
        headers: AuthHeader.headers.set(
          "language",
          (localStorage.getItem('lang')
            ? JSON.parse(localStorage.getItem('lang')).lang
            : 'en')
        ),
      });

      const scopesReq = languageReq.clone({
        headers: languageReq.headers.set(
          "scopes",
          JSON.stringify(this.auth.scopes)
        ),
      });
      const StrFecha = new Date().toString();
      const timeZone = StrFecha.match(/([+,-][0-9]{4})/)[1];
      const HorasLocalesRespectoAGmt = Number(parseInt(timeZone) / 100);
      const Horas = (Math.round(HorasLocalesRespectoAGmt));
      const Minutos = (HorasLocalesRespectoAGmt - Math.round(HorasLocalesRespectoAGmt));

      const timezoneReq = scopesReq.clone({
        headers: scopesReq.headers.set(
          "time-zone",
          JSON.stringify({
            gmt: timeZone,
            action: Horas < 0 ? 'add' : 'subtract',
            hours: Horas,
            minutes: Math.floor(Math.abs(Minutos * 100))
          })
        ),
      });

      return next.handle(timezoneReq);
    }

    return next.handle(req);
  }
}
