<div class="modal up ModalDisconnet">
  <div class="modal-content text-inicial">
    <i
      style="font-size: 20px; font-weight: bold; cursor: pointer; float:right; text-align: right;" 
      class="clospop col s12 padding0 material-icons"
      (click)="onClosed.emit(true)"
      >close
    </i>
    <div style="text-align: center">
      <div class="contFoot col lx12 col l12 col m12 col s12 padding0">
        <img
          style="width: 75px"
          src="../../../assets/images/LogoGuesthubCircula.svg"
          alt=""
        />
      </div>
      <div
        style="margin-bottom: 10px; margin-top: 10px"
        class="col lx12 col l12 col m12 col s12"
      >
        <span
          style="font-size: 24px; font-family: Open Sans"
          class="titlepop col lx12 col l12 col m12 col s12"
          >{{ 'About' | translate }}</span
        >
      </div>
      <div
        style="margin-bottom: 10px; margin-top: 10px"
        class="col lx12 col l12 col m12 col s12"
      >
        <span
          style="font-family: Open Sans; text-align: left; margin-bottom: 20px"
          class="col lx12 col l12 col m12 col s12"
          >{{ 'A unified hub to manage and improve the guest experience, from
          booking to review.' | translate }}</span
        >
        <div class="container" style="display: inline-grid; text-align: left">
          <span
            style="
              font-family: Open Sans;
              text-align: left;
              font-weight: bold;
              margin-bottom: 5px;
            "
            >{{ 'Version' | translate }}
          </span>
          <span style="font-family: Open Sans; text-align: left"
            >{{ versionWebApp }}</span
          >
        </div>
        <div class="container" style="display: inline-grid; text-align: left">
          <span
            style="
              font-family: Open Sans;
              text-align: left;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 20px;
            "
            >{{ 'Legal' | translate }}
          </span>
          <a
            style="
              font-family: Open Sans;
              text-align: left;
              margin-bottom: 5px;
              cursor: pointer;
              text-decoration: underline;
              color: #707070;
            "
            href=" https://www.guesthub.io/terms/"
            >{{"Term & Conditions" | translate}}</a
          >
          <a
            style="
              font-family: Open Sans;
              text-align: left;
              margin-bottom: 5px;
              cursor: pointer;
              text-decoration: underline;
              color: #707070;
            "
            href="http://guesthub.io/privacy-policy"
            >{{"Privacy Policy"| translate}}</a
          >
        </div>
        <div class="container" style="display: inline-grid; text-align: left">
          <span
            style="
              font-family: Open Sans;
              text-align: left;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 20px;
            "
            >{{ 'developed by' | translate }}
          </span>
          <a
            style="
              font-family: Open Sans;
              text-align: left;
              margin-bottom: 5px;
              cursor: pointer;
              text-decoration: underline;
              color: #707070;
            "
            href=" https://www.norelian.com"
            >www.norelian.com</a
          >
        </div>
        <div class="container" style="display: inline-grid; text-align: left">
          <span
            style="
              font-family: Open Sans;
              text-align: left;
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 20px;
            "
            >{{ 'Support' | translate }}
          </span>
          <a
            (click)="mailMe()"
            style="
              font-family: Open Sans;
              text-align: left;
              margin-bottom: 15px;
              cursor: pointer;
              text-decoration: underline;
              color: #707070;
            "
            >{{"support@guesthub.io"| translate}}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div (click)="onClosed.emit(true)" class="modal-overlay ups"></div>
