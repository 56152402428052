import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RequestsManagerService } from "../../service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { Auth0Service } from "../../../auth0.service";
import { ProgrammingRepeatService } from "../../../components/programing-repeat/service";

@Component({
  selector: "app-requests-manager-preview-detail",
  templateUrl: "./template.html",
  styleUrls: ["./styles.scss"],
})
export class MyComponent implements OnInit {
  @Output() otherActionEvent = new EventEmitter<any>();
  @Input() users;
  @Input() request: any;
  @Input() countSumate: number;
  editRoom: boolean;
  langug = "en";
  offset: number;
  searcAssignee: string;
  priorities: any = [
    {
      name: "Highest",
      severity: 1,
      codename: "highest",
      colorHex: "priority-highest",
      icon: "arrow_upward"
    },
    {
      name: "High",
      severity: 2,
      codename: "high",
      colorHex: "priority-high",
      icon: "arrow_upward"
    },
    {
      name: "Medium",
      severity: 3,
      codename: "medium",
      colorHex: "priority-medium",
      icon: "remove"
    },
    {
      name: "Low",
      severity: 4,
      codename: "low",
      colorHex: "priority-low",
      icon: "arrow_downward"
    },
    {
      name: "Lowest",
      severity: 5,
      codename: "lowest",
      colorHex: "priority-lowest",
      icon: "arrow_downward"
    }
  ];
  setInterval: any;
  statusSelected: null;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private service: RequestsManagerService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    public auth: Auth0Service,
    protected programmingRepeatService: ProgrammingRepeatService
  ) {
    this.offset = (new Date().getTimezoneOffset());
    this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    translate.onLangChange.subscribe(() => {
      this.langug = JSON.parse(localStorage.getItem("lang")).lang;
    });
  }

  ngOnInit() { }

  updateStatus(status, comment: string) {
    if (status.codename == 'cancelled'
      || status.codename == 'validate') {
      this.otherActionEvent.emit({ type: status.codename, value: null });
    } else if (status.codename == 'finished' && this.request.statusCodename == 'returned') {
      this.otherActionEvent.emit({ type: "returned", value: null });
    } else {
      this.request.statusName = status.name;
      this.request.statusCodename = status.codename;
      this.request.statusColorHex = status.colorHex;
      this.request.otherStatuses.splice(this.request.otherStatuses.map(s => s.codename).indexOf(status.codename), 1);
      this.service.updateStatus({
        id: this.request.id
      }, {
        codename: this.request.statusCodename,
        reason: comment.length > 0 ? comment : null
      }).then(() =>
        this.request.statusCodename == 'accepted'
          ? this.openSnackBar(this.translate.instant("The ticket has been processed"), '✓')
          : this.request.statusCodename == 'confirmed'
            ? this.openSnackBar(this.translate.instant("The ticket has been confirmed"), '✓')
            : null
      );
    }
  }

  sendQuickComment(value: string) {
    this.updateStatus(this.statusSelected, value);
    this.statusSelected = null;
  }

  getInformationReservation() {
    if (this.request.reservationId) {
      this.otherActionEvent.emit({ type: 'guest-information', value: null });
    }
  }

  updateRoom(type, value) {
    if (value && value.toString().trim().length > 0) {
      this.otherActionEvent.emit({ type: 'update:where', value: value.toString().trim() });
      this.editRoom = false;
    } else {
      this.openSnackBar(this.translate.instant("Field where is required"), '✖');
    }
  }

  updateStateTime(state: string) {
    this.request = Object.assign({}, this.request, {
      stateTime: state
    });
  }

  updatePriority(priority) {
    this.request.priorityName = priority.name;
    this.request.prioritySeverity = priority.severity;
    this.request.priorityCodename = priority.codename;
    this.request.priorityColorHex = priority.colorHex;
    this.request.priorityIcon = priority.icon;
    this.service
      .updatePriority({ id: this.request.id }, {
        codename: this.request.priorityCodename,
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Request has been successfully updated"),
          "✓"
        );
      });
  }

  onKeyUpFuq(question: string, reply: string) {
    clearInterval(this.setInterval);
    this.setInterval = undefined;

    if (!this.setInterval) {
      let intervalInput = false;
      this.setInterval = setInterval(() => {
        if (!intervalInput) {
          intervalInput = true;
          this.service
            .updateFuq({ id: this.request.id }, {
              question,
              reply,
            })
            .then(() => {
              clearInterval(this.setInterval);
              this.setInterval = null;
              intervalInput = false;
            })
            .catch(() => {
              clearInterval(this.setInterval);
              this.setInterval = undefined;
              intervalInput = false;
            });
        } else {
          clearInterval(this.setInterval);
          this.setInterval = undefined;
          intervalInput = false;
        }
      }, 2000);
    }
  }

  updateAssignee(event): void {
    const user = event.option.value;
    this.request.assigneeId = user && user.id ? user.id : null;
    this.request.assigneeFirstName =
      user && user.firstName ? user.firstName : null;
    this.request.assigneeLastName =
      user && user.lastName ? user.lastName : null;
    this.request.assigneePicture = user && user.picture ? user.picture : null;

    this.service
      .updateAssignee({ id: this.request.id }, {
        id: this.request.assigneeId
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Request has been successfully updated"),
          "✓"
        );
      });
  }

  removeAssignee() {
    this.request.assigneeId = null;
    this.request.assigneeFirstName = null;
    this.request.assigneeLastName = null;
    this.request.assigneePicture = null;
    this.service
      .updateAssignee({ id: this.request.id }, {
        id: this.request.assigneeId,
      })
      .then(() => {
        this.openSnackBar(
          this.translate.instant("Request has been successfully updated"),
          "✓"
        );
      });
  }

  openSnackBar(message?: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

}
