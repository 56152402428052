import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppNotificationsComponent } from "./component";

import { NotificationTypeDefaultModule } from './default/module';
import { TopService } from "../../service";

@NgModule({
  declarations: [AppNotificationsComponent],
  imports: [
    CommonModule,
    NotificationTypeDefaultModule
  ],
  exports: [AppNotificationsComponent],
  providers: [TopService],
})
export class NotificationDetailModule {}
