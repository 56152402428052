import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../services/url-builder.service";
import { environment } from "../../../environments/environment";
import { format } from "date-fns";
import { Auth0Service } from "../../auth0.service";

@Injectable()
export class GuestsListService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  filter(typeguest): Promise<any> {
    return typeguest.onlycheckedout === "checked-out"
      ? this.http
        .get<any>(
          `${environment.backend.url}/guests/checked-out?fromDate=${format(
            typeguest.fromDate,
            "yyyy-MM-dd"
          )}&toDate=${format(typeguest.toDate, "yyyy-MM-dd")}`
        )
        .toPromise()
        .catch(console.error)
      : typeguest.onlycheckedout === "arrivals"
        ? this.http
          .get<any>(
            `${environment.backend.url}/guests/arrivals?fromDate=${format(
              typeguest.fromDate,
              "yyyy-MM-dd"
            )}&toDate=${format(typeguest.toDate, "yyyy-MM-dd")}`
          )
          .toPromise()
          .catch(console.error) : this.http
            .get<any>(`${environment.backend.url}/guests/in-house`)
            .toPromise();
  }
  all(options): Promise<any> {
    return this.http
      .get<any>(
        `${environment.backend.url}/guests?fromDate=${format(
          options.fromDate,
          "yyyy-MM-dd"
        )}&toDate=${format(options.toDate, "yyyy-MM-dd")}`
      )
      .toPromise();
  }

  reservationsListNew(filter): Promise<any> {
    return this.http.get<any>(`${this.urlbuilder.guestappApi
      .concat('/')
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/reservations")
      .concat(`?${filter.fromDate && filter.fromDate != 'null'
        ? "fromDate=" + new Date(filter.fromDate).toISOString()
        : ""}${filter.toDate && filter.toDate != 'null'
          ? "&toDate=" + new Date(filter.toDate).toISOString()
          : ""}`)
      }`)
      .toPromise();
  }
}
