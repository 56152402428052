import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';





import { UrlBuilderService } from './url-builder.service'

@Injectable()
export class GetPermissionsService {
  /**
   * GetRequestService constructor
   * @param http Http variable
   * @param urlbuilder UrlBuilderService instance, used to concat strings (urls)
   */
  constructor(private http: HttpClient,
    private urlbuilder: UrlBuilderService
  ) { }

  /**
   * Method made to handle errors if any
   * @param error Error description
   */
  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }

  /**
   * Method made to convert data into json objects
   * @param res Data to convert
   */
  private extractData(res: any) {
    let body = res;
    return body.data || {};
  }

  /**
   * Method creates request sended to backend.
   * Contains four elements as described in backend API.
   */


  getGuesthubPermissions(): Promise<any> {

    return this.http.get<any>(this.urlbuilder.getGuesthubPermissions())
      .toPromise()
      .then(resp => {


        return resp;
      })
      .catch(this.handleError);
  }

  setNotifiLineline(user, online, tenat): Promise<any> {

    return this.http.put<any>(this.urlbuilder.setLifeline(user, online, tenat), '')
      .toPromise()
      .then(resp => {


        return resp;
      })
      .catch(this.handleError);


  }

}
