import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { DatePipeComponent } from './datepipe.component';
import { LocalizedDatePipe } from "../localized.data.pipe";

@NgModule({
  declarations: [ 
    DatePipeComponent,
    LocalizedDatePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [ 
    DatePipeComponent,
    LocalizedDatePipe
  ],
})
export class DatePipeModule {}