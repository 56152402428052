<div class="row">
  <div *ngIf="loading" class="col s12" style="text-align: center; margin-top: 15%;">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-green-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading">
    <div class="col s12 " style="text-align: center;margin-top:15%">
      <img src="assets/images/logo-guesthub.png" alt="" style="width: 150px;" />
      <h3 style="margin-top: 0px; height: 15px;">
        <strong class=" msgintegrations">
          {{ 'No Integrations configured' | translate }}<br />
        </strong>
      </h3>
    </div>
    <br />
    <br />
    <div class=" contbtn col s12">
      <a class="btn  btn-small   " [routerLink]="['/settings/integrations']">
        {{ 'Configure Guest Integration' | translate }}
      </a>
    </div>
    <br />
    <br />
  </div>
</div>