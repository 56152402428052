
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlBuilderService } from '../../services/url-builder.service';
import { Auth0Service } from "../../auth0.service";

@Injectable({
    providedIn: 'root'
})
export class SideService {

    constructor(
        private http: HttpClient,
        private urlbuilder: UrlBuilderService,
        private auth: Auth0Service
    ) {
    }

    private calculateTypeOutlet(outleType: string): string {
        return outleType === 'restaurant' || outleType === 'restaurants'
            ? 'restaurants'
            : outleType === 'service' || outleType === 'services'
                ? 'services'
                : '';
    }

    listOutletsWhitMenuActives(outleType: string): Promise<any> {
        return this.http.get<any>(`${this.urlbuilder.outletsApi
            .concat("/")
            .concat(
                this.auth.getChosenProperty.toString()
            )
            .concat("/")
            .concat(this.calculateTypeOutlet(outleType))
            .concat("/menus-actives")}`).toPromise();
    }

    sidebarMenuNotifications(filter: any) {
        const URL = `${this.urlbuilder.propertyApi
            .concat("/")
            .concat(
                this.auth.getChosenProperty.toString()
            )
            .concat("/user/")
            .concat(this.auth.userProfile.id)
            .concat("/sidebar")}`;
        // if (onlyCount) {
        // return this.http.patch<any>(URL, {}).toPromise();
        // } else {
        return this.http.get<any>(URL).toPromise();
        // }
    }
}