import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { EnabledIntegrationsModule } from "../../settings/integrations/enabled/module";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { EditorWebComponent } from "./component";
import { NgxSummernoteModule } from 'ngx-summernote';
import { EditorWebService } from "./service";
@NgModule({
  declarations: [EditorWebComponent],
  providers: [EditorWebService],
  imports: [
    CommonModule,
    TranslateModule,
    EnabledIntegrationsModule,
    TooltipModule.forRoot(),
    NgxSummernoteModule
  ],
  exports: [
    CommonModule,
    EditorWebComponent
  ]
})
export class EditorWebModule { }
