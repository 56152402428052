import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "./services/url-builder.service";
import { Auth0Service } from "./auth0.service";

@Injectable({
  providedIn: "root",
})
export class EventService {
  channels: {
    request: Subject<any>;
    foodAndBeverage: Subject<any>;
    notifications: Subject<any>;
    outlets: Subject<any>;
    outletSetting: Subject<any>;
    checkin: Subject<any>;
    guestItemsLostFound: Subject<any>;
    guestItemsPackages: Subject<any>;
    locations: Subject<any>;
    tasksManager: Subject<any>;
  } = {
      request: new Subject<any>(),
      foodAndBeverage: new Subject<any>(),
      notifications: new Subject<any>(),
      outlets: new Subject<any>(),
      outletSetting: new Subject<any>(),
      checkin: new Subject<any>(),
      guestItemsLostFound: new Subject<any>(),
      guestItemsPackages: new Subject<any>(),
      locations: new Subject<any>(),
      tasksManager: new Subject<any>(),
    };
  eventSource;
  loading = false;
  eventSourceNew;
  loadingNew = false;

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    protected auth: Auth0Service
  ) { }

  public initNew(propertyId: string) {
    this.eventSourceNew = new window["EventSource"](
      `${environment.events.api}/${propertyId}`
    );
    this.subscribeHandlersNew(this.eventSourceNew);
    this.eventSourceNew.onerror = function (event) {
      if (!this.loadingNew) {
        this.eventSourceNew.close();
        this.eventSourceNew = undefined;
        setTimeout(
          function () {
            this.initNew(propertyId);
          }.bind(this),
          2 * 5000
        );
        this.loadingNew = true;
      }
    }.bind(this);
    this.loadingNew = false;
  }

  private subscribeHandlersNew(eventSource) {
    eventSource.addEventListener("requests:locations:list", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.locations.next(Object.assign({}, ev));
    });

    /* Requests */
    eventSource.addEventListener("request:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getRequestById({ id: ev.id })
        .then(response => {
          this.channels.request.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("request:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getRequestById({ id: ev.id })
        .then(response => {
          this.channels.request.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });
    eventSource.addEventListener("request-guest:deleted", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getRequestById({ id: ev.id })
        .then(response => {
          this.channels.request.next(Object.assign({}, response, {
            action: 'guest:deleted'
          }));
        });
    });

    /* Outlet */
    eventSource.addEventListener("guesthub:outlets:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.outlets.next(Object.assign({}, ev, {
        action: 'updated',
        stateTime: 'successful'
      }));
    });
    eventSource.addEventListener("guesthub:outlets:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.outlets.next(Object.assign({}, ev, {
        action: 'created',
        stateTime: 'successful'
      }));
    });


    /* Checkin */
    eventSource.addEventListener("guesthub:checkin:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.checkin.next(Object.assign({}, ev, {
        action: 'updated',
        stateTime: 'successful'
      }));
    });
    eventSource.addEventListener("guesthub:checkin:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.checkin.next(Object.assign({}, ev, {
        action: 'created',
        stateTime: 'successful'
      }));
    });

    /* Tasks Manager */
    eventSource.addEventListener("guesthub:tasks-manager:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getTaskById({ id: ev.id })
        .then(response => {
          this.channels.tasksManager.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("guesthub:tasks-manager:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getTaskById({ id: ev.id })
        .then(response => {
          this.channels.tasksManager.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });
    eventSource.addEventListener("guesthub:tasks-manager:deleted", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.tasksManager.next(Object.assign({}, { id: ev.id }, {
        action: 'deleted'
      }));
    });

    /* Guest Items Lost & Found */
    eventSource.addEventListener("guesthub:guestItemsLostFound:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getLostFoundById({ id: ev.id })
        .then(response => {
          this.channels.guestItemsLostFound.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("guesthub:guestItemsLostFound:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getLostFoundById({ id: ev.id })
        .then(response => {
          this.channels.guestItemsLostFound.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });

    /* Guest Items Packages */
    eventSource.addEventListener("guesthub:guestItemsPackages:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getLostFoundById({ id: ev.id })
        .then(response => {
          this.channels.guestItemsPackages.next(Object.assign({}, response, {
            action: 'updated'
          }));
        });
    });
    eventSource.addEventListener("guesthub:guestItemsPackages:created", (event: any) => {
      const ev = JSON.parse(event.data);
      this.getLostFoundById({ id: ev.id })
        .then(response => {
          this.channels.guestItemsPackages.next(Object.assign({}, response, {
            action: 'created'
          }));
        });
    });

    /* Notifications */
    eventSource.addEventListener("guesthub:notifications:updated", (event: any) => {
      const ev = JSON.parse(event.data);
      // this.getNotificationById({ id: ev.id })
      //   .then(response => {
      this.channels.notifications.next(Object.assign({}, ev, {
        action: 'updated'
      }));
      // });
    });
    eventSource.addEventListener("guesthub:notifications:created", (event: any) => {
      const ev = JSON.parse(event.data);
      if (ev.ids && ev.ids.length > 0) {
        Promise.all(ev.ids.map(id =>
          this.getNotificationById({ id })
            .then(response => {
              if (response.userId.toString() == this.auth.userProfile.id.toString()) {
                this.channels.notifications.next(Object.assign({}, response, {
                  action: 'created'
                }));
              }
            })
        ))
      } else {
        this.channels.notifications.next(Object.assign({}, {
          action: 'refresh-all'
        }));
      }
    });
    eventSource.addEventListener("guesthub:notifications:deleted", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.notifications.next(Object.assign({}, {
        action: 'deleted',
        id: ev.id
      }));
    });
    eventSource.addEventListener("guesthub:notifications:deleted-all", (event: any) => {
      const ev = JSON.parse(event.data);
      this.channels.notifications.next(Object.assign({}, {
        action: 'deleted-all'
      }));
    });
  }

  private getTaskById(input) {
    return this.http.get<any>(`${this.urlbuilder.tasksManagerApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  private getLostFoundById(input) {
    return this.http.get<any>(`${this.urlbuilder.guestItemsApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/lost-found/")
      .concat(input.id)}`).toPromise();
  }

  private getRequestById(input) {
    return this.http.get<any>(`${this.urlbuilder.requestsApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/")
      .concat(input.id)}`).toPromise();
  }

  private getNotificationById(input) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        localStorage.getItem("chosen_property")
      )
      .concat("/notification")
      .concat("/")
      .concat(input.id)}`).toPromise();
  }
}
