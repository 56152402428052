import { Injectable } from "@angular/core";
import { UrlBuilderService } from "./services/url-builder.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Auth0Service } from "./auth0.service";

@Injectable()
export class FeaturesService {
  constructor(
    private http: HttpClient,
    private urlBuilder: UrlBuilderService
  ) {}

  private getFeaturesFromServer() {
    return this.http
      .get<any>(this.urlBuilder.settings().getAllModules())
      .toPromise();
  }

  
  getAllModules(): Promise<any> {
    return this.getFeaturesFromServer();
  }
}
