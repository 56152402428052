import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationHeaderTitleService {
    protected observable = new Subject<any>();
    public params: any = {
        hidden: true,
        title: {
            value: null
        },
        description: {
            value: null
        },
        style: {
            class: "col l12 m12 s12"
        },
        goback: {
            value: null,
            params: {}
        },
        minimal: false
    };

    public next(item: any) {
        this.observable.next(item);
    }

    public subscribe(callback: (item: any) => void) {
        this.observable.subscribe(callback);
    }
}
