<nav class="navbar-default navbar-static-side" id="navbnsar-sidebar" role="navigation" style="
    z-index: 0 !important;
    background-color: transparent;
    box-shadow: none !important;
  " [ngClass]="{'heigth50':myProperty.name.length < 30,'heigth70':myProperty.name.length > 30}">
  <div class="sidebar-collapse row" style="margin-left: 5px !important;">
    <ul class="col s12 padding0">
      <li class="col s12">
        <div class="row padding0 pointer" style="margin-top: 15px; margin-bottom: 15px"
          *ngIf="auth.isAuthenticated && showSide" [matMenuTriggerFor]="menu">
          <div class="col s2 padding0">
            <img [src]="myProperty.logo" width="30" class="circle margin-top-5" alt="" />
          </div>
          <div class="col s8 padding0 tenant">
            <span mat-button class="dropproperty pointer colorFont" (click)="showCangeTenat()">
              {{ myProperty.name }}
            </span>
          </div>
          <div class="col s2 padding0" *ngIf="showSide" (click)="showCangeTenat()">
            <i class="right arrow-rigth-icons icon-color"></i>
          </div>
          <mat-menu #menu="matMenu" class="menuproperty">
            <div *ngFor="let property of guesthubContextTenats ; let i = index; ">
              <a mat-menu-item (click)="showInfo(property.id)">{{property.name}}</a>
            </div>
          </mat-menu>
        </div>
      </li>

      <li class="col s12 padding0" [ngClass]="{'margin-top15':myProperty.name.length > 30}" *ngIf="auth.isAuthenticated &&
        (auth.userHasScopes(['requests:create'])
        || auth.userHasScopes(['pickup:create'])
        || (auth.userHasScopes(['guest-items:packages:create']) ||
          auth.userHasScopes(['guest-items:lost:create']) ||
          auth.userHasScopes(['guest-items:found:create']))
        ) && showSide">
        <a routerLink="/requests/create"
          class="col m10 l10 xl10 s10 offset-s1 offset-l1 offset-xl1 btn btn-small white-text efectobtn" id="naviB">
          {{ "New Request" | translate }}
        </a>
        <br />
      </li>
      <li class="col s12 padding0">
        <div class="logo-element" *ngIf="auth.isAuthenticated && !showSide">
          <div *ngIf="auth.isAuthenticated" dropdown class="exnav">
            <a class="dropproperty pointer" [matMenuTriggerFor]="menu">
              <img [src]="myProperty.logo" width="40" class="iconViewMobilePc circle" />
            </a>
            <a *ngIf="auth.isAuthenticated && !showSide && auth.userHasScopes(['requests:view'])&& auth.userHasScopes(['requests:create'])"
              routerLink="/requests/create" class="btn btn-primary btn-small efectobtn"
              style="margin-left: 12px !important; margin-top: -20px !important">
              <i class="material-icons white-text">add</i>
            </a>
            <mat-menu #menu="matMenu" class="menuproperty" style="position: absolute; left: 0px; top: 0px">
              <div *ngFor="let property of guesthubContextTenats ; let i = index; ">
                <a mat-menu-item (click)="showInfo(property.id)">{{property.name}}</a>
              </div>
            </mat-menu>
          </div>
        </div>
      </li>

      <app-guesthub-navigation-side-menu></app-guesthub-navigation-side-menu>

      <li *ngFor="let menu of listMenus" class="col s12 padding5 li-heigth " [ngClass]="{ 'active_menu': menu.active}"
        [style.display]="menu.enabled ? 'block' : 'none'">
        <a triggers="mouseenter:mouseleave click" class="edit__li__side"
          [ngClass]="{ 'poninterDisplaySideBar': !showSide }" [isDisabled]="showSide" placement="right"
          tooltip="{{ menu.placeholder | translate }}">
          <i (click)="selectMenuItem(menu)" *ngIf="menu.icon" class="{{ menu.icon }}"
            style="float: left; display: block; width: 8%" [ngClass]="{ 'iconSidaBar': !showSide}">
            <i (click)="selectMenuItem(menu)" class="restCount"
              *ngIf="menu.count > 0 || (notificationStatus[menu.notificationSound] && notificationStatus[menu.notificationSound].count > 0)"
              style="top: 0px">
              {{ menu.count || (notificationStatus[menu.notificationSound] &&
              notificationStatus[menu.notificationSound].count) }}
            </i>
          </i>
          <span (click)="selectMenuItem(menu)" class="nav-label" *ngIf="showSide" style="
              margin-left: 10px !important;
              float: left;
              display: block;
              width: 70%;
            ">
            {{ menu.name | translate }}
          </span>
          <!-- <i (click)="selectMenuItem(menu)" class="restCount" *ngIf="menu.count > 0" style="top: 287px"> {{
                        menu.count }} </i> -->
          <i class="material-icons right margin-top-10 ri__ic__poss icon-color"
            (click)="menu.desplegate = !menu.desplegate;" *ngIf="!menu.desplegate && menu.items.length > 0 && showSide"
            style="width: 10%; margin-left: 0; display: block">keyboard_arrow_right</i>
          <i class="material-icons right margin-top-10 ic__poss icon-color activeSubmenu"
            (click)="menu.desplegate = !menu.desplegate;" *ngIf="menu.desplegate && menu.items.length > 0 && showSide"
            style="width: 10%; margin-left: 0; display: block">keyboard_arrow_down</i>
        </a>

        <ul *ngIf="menu.desplegate" class="nav nav-second-level collapse col s11 margin-left15"
          [ngClass]="{'in': menu.active, 'float_menu padding0': !showSide}">
          <li *ngFor="let item of menu.items" class="col s12 padding0 li-heigth"
            [ngClass]="{'active2': item.active, 'active': item.active && !showSide}"
            [style.display]="item.enabled ? 'block' : 'none'">
            <a (click)="selectSubMenuItem(menu, item)" [isDisabled]="showSide"
              tooltip="{{ item.placeholder | translate }}" container="body" [adaptivePosition]="false" placement="auto"
              class="padding0 hei__txt__rest">
              <i *ngIf="item.icon" class="{{ item.icon }}" [ngClass]="{'icon-with-icon': item.icon}"></i>
              <span class="txt__rest" [ngClass]="{'with-icon': item.icon}">
                {{ item.name | translate }}
              </span>
              <i class="sub__notify" *ngIf="item.count > 0">
                {{ item.count }}
              </i>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>

<app-guesthub-component-modal-confirmed *ngIf="popupVisible" [config]="configModalChangePropertyConfirm"
  (confirm)="chooseProperty()" (cancel)="showInfo(0)"></app-guesthub-component-modal-confirmed>