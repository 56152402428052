import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { NotificationsComponent } from "./component";
import { NotificationsService } from "./service";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RouterModule } from '@angular/router';
import { ComponentLoadingModule } from "../../../components/loading/module";

import { NotificationDetailModule } from "./detail/module";

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TooltipModule,
    RouterModule,
    NotificationDetailModule,
    ComponentLoadingModule
  ],
  exports: [NotificationsComponent],
  providers: [NotificationsService],
})
export class NotificationsModule { }
