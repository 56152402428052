<div class="col s12 xl12 padding0" *ngIf="!visible" id="{{Id}}">
  <div
    style="color: var(--gh-color-letter);"
    [ngxSummernote]="config"
    [ngxSummernoteView]="html"
    (summernoteModelChange)="changeEditorHtml($event)"
  ></div>
</div>

<div class="col s10 l10 xl10 offset-l1 offset-xl1 center" *ngIf="visible">
  <div class="col s12 l12 m12 xl12"><br /><br /><br /></div>
  <div class="preloader-wrapper big active">
    <div class="spinner-layer spinner-green-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>
      <div class="gap-patch">
        <div class="circle"></div>
      </div>
      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</div>
