import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-guesthub-modal-preview-img',
    templateUrl: 'template.html',
    styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
    @Output() close = new EventEmitter();
    @Input() img: {
        imgList: any[],
        currentImg: string
    };
    i = 0;
    url: string;

    constructor() {
    }

    ngOnInit() {
        this.i = this.img.imgList.findIndex(img => img.url === this.img.currentImg)
        this.showSlide(this.img.imgList, this.i)
    }

    showSlide(slides, i: number) {
        this.url = slides[i].url;
    }

    getPrev(slides, i: number) {
        this.i = Math.max(i - 1, 0);
        this.showSlide(slides, this.i);
    }

    getNext(slides, i: number) {
        this.i = Math.min(i + 1, slides.length - 1);
        this.showSlide(slides, this.i);
    }

}