import { SearchFilterPipe } from "./SearchFilterPipe";
import { NgModule } from "@angular/core";
import { FilterPipe } from "./SearchAll.pipe";
import { from } from "rxjs";
import { DemoNumber } from "./filter.for.pipe";
import { ApplicationPipesModule } from "../pipes/application-pipes.module";
@NgModule({
  imports: [ApplicationPipesModule],
  declarations: [SearchFilterPipe, FilterPipe, DemoNumber],
  exports: [SearchFilterPipe, FilterPipe, DemoNumber],
})
/**
 * *ngFor="let user of users | paginate: {itemsPerPage: pageSize,
                                           currentPage: page,
                                           totalItems: users.length} | filterAll: searchString"
 */
export class SearchModuleParams {}
