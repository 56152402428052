import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-guesthub-component-monitor-no-result',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}