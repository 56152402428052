import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { UrlBuilderService } from "./url-builder.service";

@Injectable()
export class GetRequestService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService
  ) {}

  execute(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.requests.get(id))
      .toPromise()
      .then((request) => ({
        id: request.id,
        assignee: request.assignee,
        executionDate: request.executionDate,
        time: request.process.time,
        nextFinishedExecutionTime: request.nextFinishedExecutionTime,
        creationDate: request.creation.date,
        roomNumber: request.location.roomNumber,
        publicArea: request.location.publicArea,
        otherLocation: request.location.otherLocation,
        guestName: request.location.guestName,
        reservationId: request.location.reservationId
          ? request.location.reservationId
          : null,
        guestInformation: request.guestInformation
          ? request.guestInformation
          : null,
        suggestedSubCategory:
          request.process.suggestedSubCategory ||
          (request.process.Subcategory && request.process.Subcategory.names)
            ? null
            : request.process.Subcategory,
        deparmentSuggested: request.deparmentSuggested,
        followUp: request.process
          ? request.process.Subcategory
            ? request.process.Subcategory.followUp
            : undefined
          : undefined,
        processCategoryName: request.process.category,
        processName: request.process.Subcategory,
        requestText: request.process.requestText,
        requestType: request.type,
        status: request.status,
        modification: request.modification,
        additionalNotes: request.notes,
        newStatusTime: request.process.time
          ? request.process.time.newStatus
          : undefined,
        followUpTimeHours: request.process.time
          ? request.process.time.followUp
          : undefined,
        timeExecutionHours: request.process.time
          ? request.process.time.execution
          : undefined,
        followUpAnswers: request.followUpQuestions.map((followUpQuestion) => ({
          followUpQuestionText: followUpQuestion.question,
          value: followUpQuestion.value,
        })),
        ticket: {
          id: request.ticket.id,
          statusId: request.status.id,
          colorHex: request.status.color,
          departmentId: request.process.department
            ? request.process.department.id
            : null,
          assigneedUserId: request.assignee.id,
          priorityId: request.priority.id,
          department: request.process.department,
          priority: {
            id: request.priority.id,
            name: request.priority.name,
            colorHex: request.priority.color,
            severity: request.priority.severity,
          },
          assigneedUser: {
            id: request.assignee.id ? request.assignee.id : 0,
            firstName: request.assignee.name
              ? request.assignee.name.split(" ")[0]
              : "",
            lastName: request.assignee.name
              ? request.assignee.name
                  .split(" ")
                  .filter((fullName, index) => index !== 0)
                  .join(" ")
              : "",
            email: request.assignee.email ? request.assignee.email : "",
          },
        },
        creationUserJSON: JSON.stringify({
          firstName: request.creation ? request.creation.firstName : "",
          lastName: request.creation ? request.creation.lastName : "",
        }),
        modificationUserJSON: JSON.stringify({
          firstName: request.modification ? request.modification.firstName : "",
          lastName: request.modification ? request.modification.lastName : "",
        }),
      }))
      .catch(console.error);
  }
}
