import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyComponent } from './component';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalChangeImagenService } from './service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from "../../material.module";
import { EnabledIntegrationsModule } from "../../settings/integrations/enabled/module";
import { FormsModule } from '@angular/forms';
@NgModule({
    declarations: [
        MyComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        DateRangePickerModule,
        TooltipModule.forRoot(),
        MaterialModule,
        EnabledIntegrationsModule,
        ImageCropperModule,
        TranslateModule,
        FormsModule
    ],
    exports: [
        MyComponent
    ],
    providers: [ModalChangeImagenService]
})
export class ComponentModalChangeImagenModule { }
