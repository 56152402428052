import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../../services/url-builder.service";
import { Auth0Service } from "../../../auth0.service";

@Injectable()
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) { }

  listNotifications(page?: number) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications${page ? ('?page=' + page) : ''}`)
      }`).toPromise();
  }

  listNotificationsUnread(page?: number) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications-unread${page ? ('?page=' + page) : ''}`)
      }`).toPromise();
  }

  countNotifications(query?) {
    return this.http.get<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications-count${query && query.notificationSound
        ? `?notificationSound=${query.notificationSound}`
        : ``}`)
      }`).toPromise();
  }

  markToRead(id) {
    return this.http.put<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat(`/notification/${id}/read`)
      }`, {}).toPromise();
  }

  deleteAll() {
    return this.http.delete<any>(`${this.urlbuilder.propertyApi
      .concat("/")
      .concat(
        this.auth.getChosenProperty.toString()
      )
      .concat("/user/")
      .concat(
        this.auth.userProfile.id.toString()
      )
      .concat(`/notifications`)
      }`, {}).toPromise();
  }
}
