import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitBigWord10'
})
export class SplitBigWord10Pipe implements PipeTransform {
  transform(val:string):string {
    if(val){
        let words = val.split(" ");
        for (let i = 0; i < words.length; i++) {
          if (words[i].length > 10)
              words[i] =  words[i].substr(0, 10) + '-' + words[i].substr(10);
        }
        return words.join(" ");
    }else{
        return "";
    }
  }
}

@Pipe({
  name: 'splitBigWord15'
})
export class SplitBigWord15Pipe implements PipeTransform {
  transform(val:string):string {
    if(val){
        let words = val.split(" ");
        for (let i = 0; i < words.length; i++) {
          if (words[i].length > 15)
              words[i] =  words[i].substr(0, 15) + '-' + words[i].substr(15);
        }
        return words.join(" ");
    }else{
        return "";
    }
  }
}

@Pipe({
  name: 'splitBigWord12'
})
export class SplitBigWord12Pipe implements PipeTransform {
  transform(val:string):string {
    if(val){
        let words = val.split(" ");
        for (let i = 0; i < words.length; i++) {
          if (words[i].length > 12)
              words[i] =  words[i].substr(0, 12) + '-' + words[i].substr(12);
        }
        return words.join(" ");
    }else{
        return "";
    }
  }
}