import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './component';

@NgModule({
    declarations: [
        FooterComponent
    ],
    imports: [
         CommonModule
         ],
    exports: [
        FooterComponent
    ],
    providers: [],
})
export class FooterModule {}
