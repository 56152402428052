import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-guesthub-navigation-side-menu',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @Output() output = new EventEmitter<any>();

  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {

  }
}
