<div class="modal_right_0 white up_right mygavete">
    <app-my-profile-change-password class="modal-content text-inicial white" *ngIf="!loading && changePassword"
        (output)="changePassword = false; cancel();" (goBack)="changePassword = false"></app-my-profile-change-password>

    <div class="modal-content text-inicial white" *ngIf="!loading && !changePassword">
        <div class="col s12 modal-margin-top modal-padding-leftright">
            <i class="material-icons right pointer color-base-two cancel-icon icon-back" (click)="cancel()">clear</i>
        </div>

        
        <div class="clearfix"></div>
        
        <div class="modal-padding-leftright scroll-hei">
            <div class="row card--paddincreate padding0" style="text-align: center;">
                <a class="pointer" (click)="modalChangeCover=true" *ngIf="user.picture">
                    <img [src]="user.picture" class="photoProfile" height="150" style="border-radius: 50%;" />
                    <img src="assets/images/icon-cam.svg" alt="" width="30" class="cameraProfile"
                    style="margin-left: -30px;" />
                </a>
            </div>
            <div class="row card--paddincreate padding0">
                
                <h2 style="padding: inherit;" class="col s12 color-base font24 marg-top marg-bot">
                    {{ 'My profile' | translate }}
                </h2>
                <div class="input-field col s6 padding0">
                    <input id="firstName" style="width: 90%;" width="90%" [(ngModel)]="user.firstName" maxlength="50"
                        type="text" placeholder="{{ 'First name' | translate }}" class="validate h-input clr-inp"
                        autocomplete="off" required (keyup)="_autoValidate()" />
                    <label for="firstName" class="active input-field__label poss-lab">
                        {{ 'First name' | translate }}
                    </label>
                </div>

                <div class="input-field col s6 padding0">
                    <input id="lastName" style="width: 90%;" [(ngModel)]="user.lastName" maxlength="50" type="text"
                        placeholder="{{ 'Last name' | translate }}" class="validate h-input clr-inp" autocomplete="off"
                        required (keyup)="_autoValidate()" />
                    <label for="lastName" class="active input-field__label poss-lab">
                        {{ 'Last name' | translate }}
                    </label>
                </div>

                <div class="input-field col s6 padding0">
                    <input id="phoneNumber" style="width: 90%;" [(ngModel)]="user.phoneNumber" maxlength="50"
                        type="text" placeholder="{{ 'Phone number' | translate }}" class="validate h-input clr-inp"
                        autocomplete="off"  (keyup)="_autoValidate()" />
                    <label for="phoneNumber" class="active input-field__label poss-lab">
                        {{ 'Phone number' | translate }}
                    </label>
                </div>

                <div class="input-field col s6 padding0">
                    <input id="officeNumber" style="width: 90%;" [(ngModel)]="user.officeNumber" maxlength="50"
                        type="text" placeholder="{{ 'Movil' | translate }}" class="validate h-input clr-inp"
                        autocomplete="off"  (keyup)="_autoValidate()" />
                    <label for="officeNumber" class="active input-field__label poss-lab">
                        {{ 'Movil' | translate }}
                    </label>
                </div>
                <div class="input-field col s6 padding0" *ngIf="user.department && user.department.names[0]">
                    <input id="department" style="width: 90%;" [ngModel]="user.department?.names[0]?.name" disabled
                        maxlength="50" type="text" placeholder="{{ 'Department' | translate }}"
                        class="validate h-input clr-inp" autocomplete="off" required (keyup)="_autoValidate()" />
                    <label for="department" class="active input-field__label poss-lab">
                        {{ 'Department' | translate }}
                    </label>
                </div>

                <div class="input-field col s6 padding0" *ngIf="user.usertype">
                    <input id="usertype" style="width: 90%;" [ngModel]="user.usertype.name" disabled maxlength="50"
                        type="text" placeholder="{{ 'User type' | translate }}" class="validate h-input clr-inp"
                        autocomplete="off" required (keyup)="_autoValidate()" />
                    <label for="usertype" class="active input-field__label poss-lab">
                        {{ 'User type' | translate }}
                    </label>
                </div>
            </div>
            <div>
                <div class="change-password-title">
                    {{ 'Your Password' | translate }}
                </div>
                <div class="change-password-title-content">
                    {{ 'We recommend that you change your password for security reasons or in case you have forgotten it.' | translate }}
                </div>
                <div style="margin-top: 0.5rem;" class="col s12 padding0 ">
                    <span class="change-password pointer" (click)="changePassword = true;">
                        {{ 'Change password' | translate }}
                    </span>
                </div>
            </div>

            <div class="lenguaje">
                <div class="change-laguague-title">
                    {{ 'Language' | translate }}
                </div>
                <div class="change-password-title-content">
                    {{ 'Select the language of your preference to navigate the Guesthub tool.' | translate }}
                </div>
                <div class="col s12 padding0 marg-top">
                    <div class="input-field col s6 padding0">
                        <select id="language" class="inputSelect" [(ngModel)]="user.language.id"
                            (change)="changeLanguage($event.target.value)">
                            <option *ngFor="let language of languages" [value]="language.id">
                                {{ language.name | translate }}
                            </option>
                        </select>
                        <label for="language" class="active input-field__label poss-lab">
                            {{ 'Language' | translate }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="nopadding mygavete-buttons">
                <div class="right-align">
                    <button (click)="cancel()" class="btn btn-falt-ant btn-small button-cancel" container="body"
                        placement="top">
                        {{ "Cancel" | translate }}
                    </button>
                    <button (click)="save()" [disabled]="disabledButton" class="btn btn-falt-ant btn-small button-save"
                        container="body" placement="top">
                        {{ "Save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="cols12 l12 xl12 m12 center" *ngIf="loading">
        <div class="col s12"><br /><br /></div>
        <div class="loadingdiv">
            <div class="preloader-wrapper big active">
                <div class="spinner-layer spinner-green-only">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-overlay pointer ups" (click)="cancel()"></div>

<app-guesthub-component-modal-change-imagen *ngIf="modalChangeCover"
    [ngStyle]="{'display': !modalChangeCoverVisible ? 'none' : 'block'}" [ratio]="1/1" [image]="user.picture"
    [containerName]="'users'" (onComplete)="onComplete($event)" (onError)="onError($event)"
    (closeModal)="modalChangeCoverVisible=true;modalChangeCover=false" (loading)="loadingImg($event)">
</app-guesthub-component-modal-change-imagen>