import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyComponent } from './component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        MyComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [
        MyComponent
    ],
    providers: [],
})
export class ComponentMonitorNoResultModule { }
