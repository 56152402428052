<div class="col s12 m12 l12 padding15">
    <i class="material-icons left pointer color-base-two" (click)="back()">arrow_back</i>
</div>

<h2 class="col s12 color-base font24 blod modal-padding-leftright marg-top marg-bot">
    {{ 'Change password' | translate }}
</h2>

<div class="clearfix"></div>

<div class=" modal-padding-leftright scroll-hei">
    <div class="change-sub-title">
        <span>
            {{ 'Enter between 8 and 20 alphanumeric characters and/or symbols. Please avoid using the same password from other websites.' | translate }}
        </span>
    </div>
    <div style="margin-top: 3rem;" class=" row card--paddincreate padding0">

        <div class="input-field col s12 padding0">
            <input #currentPassword id="currentPassword" [(ngModel)]="data.currentPassword" maxlength="50"
                type="password" placeholder="{{ 'Current password' | translate }}" class="validate h-input clr-inp changePass" autocomplete="off"
                required (keyup)="_autoValidate()">
            <i (click)="changeType('currentPassword')" class="material-icons prefix pointer"> visibility </i>
            <label for="currentPassword" class="active input-field__label poss-lab">
                {{ 'Current password' | translate }}
            </label>

        </div>

        <div class="input-field col s12 padding0">
            <input #newPassword id="newPassword" [(ngModel)]="data.newPassword" maxlength="50" type="password"
                placeholder="{{ 'New password' | translate }}" class="validate h-input clr-inp changePass" autocomplete="off" required
                (keyup)="_autoValidate()">
            <i (click)="changeType('newPassword')" class="material-icons prefix pointer"> visibility </i>
            <label for="newPassword" class="active input-field__label poss-lab">
                {{ 'New password' | translate }}
            </label>

        </div>

        <div class="input-field col s12 padding0">
            <input #repeatPassword id="repeatPassword" [(ngModel)]="data.repeatPassword" maxlength="50" type="password"
                placeholder="{{ 'Repeat new password' | translate }}" class="validate h-input clr-inp changePass " autocomplete="off" required
                (keyup)="_autoValidate()">
            <i (click)="changeType('repeatPassword')" class="material-icons prefix pointer "> visibility </i> <label
                for="repeatPassword" class="active input-field__label poss-lab"> {{ 'Repeat new password' | translate }}
            </label>

            <span *ngIf="errorRepeatPasswordInvalid">
                {{'Password does not match'| translate }}
            </span>
        </div>
<!-- 
        <div class="col s12 padding0">
            <strong>
                {{ 'Safety recommendations' | translate }}
            </strong>
            <p>
                {{ 'Enter between 8 and 20 characters. Do not use the same password from another site.' | translate }}
            </p>
        </div> -->
    </div>

    <div class="nopadding mygavete-buttons">
        <div class="right-align">
            <button (click)="back()" class="btn btn-falt-ant btn-small button-cancel" container="body" placement="top">
                {{ "Back" | translate }}
            </button>
            <button (click)="save()" [disabled]="disabledButton" class="btn btn-falt-ant btn-small button-save"
                container="body" placement="top">
                {{ "Save" | translate }}
            </button>
        </div>
    </div>
</div>