import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { Auth0Service } from "../../auth0.service";
import { TopService } from "./service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';

import { environment } from "../../../environments/environment";
import { ManagerColorService } from '../../manager-colors.service';
import { EventService } from "../../event.service";
import { NotificationsService } from "./notifications/service";

import { SideService } from "../side/service";

@Component({
  selector: "app-guesthub-navigation-top",
  styleUrls: ["styles.scss"],
  templateUrl: "template.html",
})
export class TopNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('myDivSimulator') myDivSimulator: ElementRef;
  @Output() outputShowSidebar: EventEmitter<Object> = new EventEmitter<
    Object
  >();
  showMenu = true;
  mailText = "";
  popupabVisible = false;
  myLanguage: any;
  modalOpenMyProfile = false;
  connectOffline = false;
  isAProd: boolean = window.location.hostname.includes('guesthub.io');
  languages: Array<any> = [
    {
      name: 'English',
      code: 'en'
    }, {
      name: 'Spanish',
      code: 'es'
    }
  ];

  private notificationSound = new Audio('assets/sound/zapsplat_multimedia_ringtone_smartphone_simple_63270.mp3');
  public soundEnabled = true;
  private isPlaying = false;
  notification: any;
  intervalSimulator: any;
  url: any = window.location.host;
  showNotifications: boolean;
  notificationsCount = 0;
  notificationsUnRead: any[] = [];
  notificationsUnReadDesplay: any[] = [];
  viewOpenPreview: any;
  viewPreview: any;
  itemSelected: { id: any; };

  constructor(
    public auth: Auth0Service,
    public translate: TranslateService,
    private topService: TopService,
    protected managerColorService: ManagerColorService,
    private notificationsService: NotificationsService,
    protected eventService: EventService,
    private sideService: SideService
  ) {
    window.addEventListener('offline', function (event) {
      this.connectOffline = true;
    }.bind(this));
    window.addEventListener('online', function (event) {
      this.connectOffline = false;
    }.bind(this));
  }

  enabledDisabledDarkMode(checked = false) {
    this.managerColorService.modeSelected = checked ? "dark" : 'white';
    localStorage.setItem('modeSelected', this.managerColorService.modeSelected);
    this.managerColorService.setColor();
  }

  goToSubscription() {
    window.location.href = environment.PROTOCOL.concat(
      environment.FRONTENDSUBSCRIPTION.HOST
    ).concat(environment.FRONTENDSUBSCRIPTION.DOMAIN);
  }

  mailMe() {
    this.mailText = `mailto:${this.translate.currentLang === "es" ? "soporte" : "support"}@guesthub.io?subject=&body=`
    window.location.href = this.mailText;
  }

  ngAfterViewInit() {
    this.myDivSimulator.nativeElement.click();
    // this.intervalSimulator = setInterval(() => {
    //   this.myDivSimulator.nativeElement.click();
    // }, 1000);
    this.initComponent();
  }

  ngOnInit() {
    this.myLanguage = {
      lang: localStorage.getItem("lang") ? JSON.parse(localStorage.getItem("lang")).lang : this.translate.currentLang,
      name: (
        localStorage.getItem("lang") ? JSON.parse(localStorage.getItem("lang")).lang : this.translate.currentLang
      ) == 'es' ? 'Spanish' : 'English'
    };
  }

  initComponent() {
    this.topService.subscribePicture((res) => {
      this.auth.userProfile.picture = res;
    });

    this.topService.activeSound$.subscribe((res) => {
      this.soundEnabled = res;
      this.sideService.sidebarMenuNotifications({})
        .then(sidebarMenu => {
          this.notification = sidebarMenu;
          this.__autoPlaySound();
        });
    });

    this.topService.refreshUserSoundPreferences$.subscribe((res) => {
      this.sideService.sidebarMenuNotifications({})
        .then(sidebarMenu => {
          this.notification = sidebarMenu;
          this.listNotificationsUnRead();
          this.__autoPlaySound();
        });
    });

    this.notificationSound.loop = true; // Hacer que el sonido se repita

    this.notificationsService.countNotifications()
      .then(notificationsCount => {
        this.notificationsCount = notificationsCount;
        if (this.notificationsCount > 0) {
          this.listNotificationsUnRead();
        }
      });

    this.sideService.sidebarMenuNotifications({})
      .then(sidebarMenu => {
        this.notification = sidebarMenu;
        this.__autoPlaySound();
      });

    const functionNotificationSidebar = () =>
      this.sideService.sidebarMenuNotifications({})
        .then(sidebarMenu => {
          this.notification = sidebarMenu;
          this.__autoPlaySound();
        });

    /* Otros eventos */
    this.eventService.channels.outlets.subscribe(functionNotificationSidebar);

    this.eventService.channels.checkin.subscribe(functionNotificationSidebar);

    this.eventService.channels.outletSetting.subscribe(functionNotificationSidebar);

    this.eventService.channels.tasksManager.subscribe(functionNotificationSidebar);
    /* Fin de otros eventos */

    this.eventService.channels.notifications.subscribe(notification => {
      functionNotificationSidebar();
      this.notificationsService.countNotifications()
        .then(notificationsCount => {
          this.notificationsCount = notificationsCount;
          if (notification.action == 'created') {
            if (!this.notificationsUnRead.find(not => not.id == notification.id)) {
              this.notificationsUnRead.unshift(notification);
            }
            if (!this.notificationsUnReadDesplay.find(not => not.id == notification.id)) {
              this.notificationsUnReadDesplay.unshift(notification);
            }
          } else {
            this.listNotificationsUnRead();
          }
        });
    });
  }

  private listNotificationsUnRead() {
    this.notificationsService.listNotificationsUnread()
      .then(notificationsUnRead => {
        this.notificationsUnRead = notificationsUnRead;
        this.notificationsUnReadDesplay = notificationsUnRead;
      });
  }

  private __autoPlaySound() {
    if (this.soundEnabled) {
      if (this.notification.some(n =>
        n.activeSound && n.count > 0
      )) {
        if (!this.isPlaying) {
          this.notificationSound.play();
          this.isPlaying = true;
        }
      } else {
        this.stopNotificationAuditive();
      }
    } else {
      this.stopNotificationAuditive();
    }
  }

  private stopNotificationAuditive() {
    this.notificationSound.pause();
    this.notificationSound.currentTime = 0;
    this.isPlaying = false;
  }

  toggleSound() {
    this.soundEnabled = !this.soundEnabled;
    if (!this.soundEnabled && this.isPlaying) {
      this.notificationSound.pause();
      this.notificationSound.currentTime = 0;
      this.isPlaying = false;
    }
  }

  ngOnDestroy() {
    this.notificationSound.pause();
    clearInterval(this.intervalSimulator);
    this.intervalSimulator = undefined;
  }

  showSidebar() {
    if (this.showMenu == true) {
      this.outputShowSidebar.emit(false);
      this.showMenu = false;
    } else {
      this.outputShowSidebar.emit(true);
      this.showMenu = true;
    }
  }

  changeLanguage(lang): void {
    this.myLanguage = {
      lang: lang,
      name: lang == 'es' ? 'Spanish' : 'English'
    };
    localStorage.setItem("lang", JSON.stringify(this.myLanguage));
    this.translate.use(lang);
  }

  activateModal() {
    // No subistes esto cris
  }

  sendCommunicationsReport() {
    this.topService.sendCommunicationsReport()
  }

  // Se desactiva debido a que solo se marcara la notificacion como leida una ves alguien cambie el estado del requests.
  // readNotification(notification) {
  //   return this.notificationsService.markToRead(notification.id)
  //     .then(() => {
  //       this.showNotifications = false;
  //       return true;
  //     });
  // }

  onActionEvent(event) {
    if (event.action == 'destroy') {
      this.notificationsUnReadDesplay = this.notificationsUnReadDesplay.filter(n =>
        n.id != event.id
      );
    } else if (event.action == 'open-modal') {
      //this.readNotification(event);
      this.itemSelected = { id: event.requestId };
      this.viewPreview = event.modeOpen;
      this.viewOpenPreview = event.module;
      this.showNotifications = false;
    } else {
      console.error("Error not spesified", event)
      // this.readNotification(event)
      //   .then(() => {
      //     console.info(event);
      //   });
    }
  }
}
