<p class="col s12 m12 xl12"></p>
<div class="modal-padding-leftright content-detail scrolling-gavete" #scrollMe>
    <span class="col s12 padding0 padding-bootom-24px" *ngFor="let his of history; let i = index">
        <div class="col s12 padding0">
            <div class="padding0 col s12 m12 l12 xl12">
                <span *ngIf="his.authorPicture" class="btn btn-default btn-floating hiss btn-small
                btn-initials" style="background: #d8d8d8 !important; margin: 2px!important;">
                    <img class="imagen-avatare" [src]="his.authorPicture" width="100%" />
                </span>
                <span class="font-name font12 blod">
                    {{ his.authorName }}
                </span>
                <span class="font-date font12 color-base"> {{ his.creationDate | date:'d MMM yyyy':'TZ' : langug |
                    uppercase |
                    translate}} </span>
                <span class="font-date font12 color-base"> {{ his.creationDate | date:'h:mm a':'TZ' : langug | uppercase
                    }}</span>
            </div>
            <div class="col s12 his padding-left-48">
                <span>
                    <span>
                        <div class="panel-body justify-text" style="font-size: 16px;margin-top: -10px;">
                            <div *ngIf="his.type == 'requests-finished'">
                                {{ "Discad this request" | translate }}
                            </div>

                            <div *ngIf="his.type == 'requests-other-reporter'">
                                {{ "Has reported this request again" | translate }}
                            </div>

                            <div *ngIf="his.type == 'comment-deleted'">
                                {{ "Deleted comment:" | translate }}
                                "{{ his.question }}"
                            </div>

                            <div *ngIf="his.type == 'requests-status'">
                                {{ "Changed status from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-priority'">
                                {{ "Changed priority from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-assignee'">
                                <ng-container *ngIf="his.from || his.to">
                                    {{ "Changed assignee" | translate }}
                                    <ng-container *ngIf="his.from">
                                        <span> {{ "from" | translate }}</span>
                                        <b> {{ his.from }}</b>
                                    </ng-container>
                                    <span> {{ "to" | translate }} </span>
                                    <b *ngIf=" !his.to"> {{ "No data" | translate }}</b>
                                    <b *ngIf=" his.to">{{ his.to }} </b>
                                </ng-container>
                            </div>

                            <div *ngIf="his.type == 'requests-change-where'" class="font16">
                                {{ "Changed location from" | translate }}
                                <b>{{ his.from | translate }}</b>
                                {{ "to" | translate }}
                                <b>{{ his.to | translate }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-fuq-updated'">
                                {{ "Changed the response of" | translate }}
                                {{ his.question }}
                                <span *ngIf="his.from"> {{ 'from' | translate }}</span>
                                <b *ngIf="his.from"> {{ his.from }}</b> {{ "to" | translate }}
                                <b *ngIf="his.to">{{ his.to }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-change-subcategory'">
                                {{ "Has changed" | translate }}
                                <b *ngIf="his.from"> {{ his.from.subcategory }} / {{his.from.category}} / {{his.from.department}}</b>
                                {{'to' | translate}}
                                <b *ngIf="his.to"> {{ his.to.subcategory }} / {{his.to.category}} / {{his.to.department}}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-process-name'">
                                {{ "Changed Subcategory name from" | translate }}
                                <b>{{ his.from }}</b> {{ "to" | translate }}
                                <b>{{ his.to }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-suggested-merge'">
                                {{ "Merged the suggested subcategory" | translate }}
                                <b>{{ his.from }}</b>
                                {{ "with the subcategory" | translate }}
                                <b>{{ his.to }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-suggested-deleted'">
                                {{ "Deleted the suggested subcategory" | translate }}
                                <b>{{ his.from }}</b>
                            </div>

                            <div *ngIf="his.type == 'requests-guest-created'">
                                {{ "Validating this guest request" | translate }}
                            </div>

                            <div *ngIf="his.type == 'requests-created'">
                                {{ "You have created this request" | translate }}
                            </div>

                            <div *ngIf="his.type == 'requests-created-guest'">
                                {{ "The guest has created this request" | translate }}
                            </div>
                        </div>
                    </span>
                </span>
            </div>
        </div>
    </span>
</div>