import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EnabledIntegrationsComponent } from "./component";
import { EnabledIntegrationsService } from "./service";
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EnabledIntegrationsComponent],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [EnabledIntegrationsComponent],
  providers: [EnabledIntegrationsService]
})
export class EnabledIntegrationsModule { }
