import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-guesthub-component-modal-about',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
  @Output() onClosed: EventEmitter<any> = new EventEmitter<any>();
  versionWebApp: any = "24.03.27";
  mailText = "";
  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {

    
  }
  mailMe() {
    this.mailText = `mailto:${this.translate.currentLang === "es" ? "soporte" : "support"}@guesthub.io?subject=&body=`
    window.location.href = this.mailText;
  }

}