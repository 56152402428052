<div class="modal-content text-inicial white">
    <div class="col s12 padding0 modal-margin-top modal-padding-leftright mod__mob"
        style="margin-bottom: 5px; margin-top: 8px !important; padding-left: 8px !important;">
        <i class="material-icons left pointer color-base-two" (click)="cancel()">chevron_left</i>
    </div>

    <div *ngIf="!loading">
        <h2 class="col s12 color-base font24 blod modal-padding-leftright marg-top marg-bot">
            {{ 'Edit Request' | translate }} #{{ request.idReference }}
        </h2>
        <div class="clearfix"></div>
        <div class=" modal-padding-leftright scroll-hei">
            <div class="row card--paddincreate padding0">
                <div class="left circle-icon circle-what">
                    <i class="material-icons">announcement</i>
                </div>
                <div class="col s10" style="padding-right: 0px; width: 89%">
                    <h3 class="padding0 font12 labels label" style="margin: 0px !important;"
                        [ngClass]="{'helperText':(!data.processId || whatList.length == 0) && dirty, 'active': focusWhat }">
                        {{ 'What' | translate | uppercase}}
                    </h3>
                    <div class="col s12 padding0" style="display: flex;">
                        <input [ngClass]="{'requeride': (!data.processId || whatList.length == 0) && dirty }" required
                            class="validate h-input clr-inp" (blur)="dirty = true ; focusWhat = false"
                            (focus)="focusWhat = true; data.requestText.length > 0 ? searchResultWhat($event): null"
                            #onFocusInputWhat (keyup)="searchResultWhat($event)"
                            placeholder="{{ 'What is needed' | translate }}" matInput [matAutocomplete]="what"
                            id="whatInput" [value]="data.requestText" />
                        <a class="cont__remove" href="javascript:;" (click)="clearWhat(true); dirty = true"
                            *ngIf=" data.requestText.length> 0">
                            <i class="material-icons pointer color-base-two cancel-icon icon__remove">clear</i>
                        </a>
                        <mat-autocomplete class="test-class" classlist="test-class" (optionSelected)="setWhat($event)"
                            #what="matAutocomplete">
                            <mat-option class="loading" *ngIf="loadingWhat">
                                <mat-spinner style="left: 50%; transform: translateX(-50%);"
                                    diameter="35"></mat-spinner>
                            </mat-option>
                            <ng-container *ngIf="!loadingWhat && whatList.length > 0">
                                <mat-option *ngFor="let what of whatList" [value]="what">
                                    {{ what.name }} / {{ what.categoryName }} / {{ what.departmentName }}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="!loadingWhat && whatList.length == 0">
                                <mat-option disabled="true">
                                    {{ 'There are no results for the search criteria used' | translate }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </div>
                    <div class="helperText" *ngIf="!data.processId && dirty">
                        {{ 'You must select at least one option' | translate }}
                    </div>

                    <div *ngIf="data.processId" class="itememail mat-chip-custom">
                        <span class="textitemsum color-base" style="font-weight: 400;">
                            <span style="font-weight: 700;">
                                {{ selectedWhat.name }}
                            </span> / {{ selectedWhat.categoryName }} / {{ selectedWhat.departmentName }}
                        </span>
                        <a class="cont__removeSub" href="javascript:;" (click)="clearWhat(); dirty = true">
                            <i style="font-size: 16px; line-height: 20px; margin-left: 4px;"
                                class="material-icons pointer color-base-two cancel-icon icon__remove">
                                close
                            </i>
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="data.followUpQuestions.length > 0" class="col s12 border__top__4line border-line "><br /></div>
            <div *ngIf="data.followUpQuestions.length > 0" class="row card--paddincreate padding0">
                <h4 class="col s12 title">
                    {{ 'Followed Up questions:' | translate }}
                </h4>
                <div class="input-field col s12" *ngFor="let f of data.followUpQuestions; let i = index">
                    <h3 class="padding0 font12 labels label" style="margin: 0px !important;"
                        [ngClass]="{'helperText':f.reply.length == 0 , 'active': focusFaq[i] }">
                        {{ f.question | uppercase }}
                    </h3>
                    <input id="fuq-id{{ i }}" #fuqAns="ngModel" type="text" class="validate" autocomplete="off"
                        (blur)="focusFaq[i] = false" ngcontrol [(ngModel)]="f.reply" (change)="changeContent()"
                        (focus)="focusFaq[i] = true" (keyup)="changeContent()" required>
                </div>
            </div>
            <div *ngIf="data.followUpQuestions.length > 0" class="col s12 border__top__4line border-line "><br /></div>

            <div class="row card--paddincreate padding0" style="margin-top: 16px;">
                <div class="left circle-icon circle-where">
                    <i class="material-icons">location_on</i>
                </div>
                <div class="col s9" style="padding-right: 0; width: 89%">
                    <h3 class="padding0 font12  labels" style="margin: 0px !important;"
                        [ngClass]="{'helperText':!data.whereName && dirty , 'active': focus}">
                        {{ 'Where' | translate | uppercase}}
                    </h3>
                    <div class="col s12 padding0" style="display: flex;">
                        <input required [ngClass]="{'requeride': !data.whereName && dirty }"
                            class="validate h-input clr-inp" (blur)="dirty = true ; focus = false"
                            (focus)="focus = true" #onFocusInputWhere (keyup)="searchResultWhere($event)"
                            placeholder="{{ 'Room number or public area' | translate }}" matInput
                            [matAutocomplete]="where" id="where" [value]="data.whereName" />
                        <a class="cont__remove" href="javascript:;" (click)="clearWhere()" *ngIf="searchTxt.length > 0">
                            <i class="material-icons pointer color-base-two cancel-icon icon__remove">clear</i>
                        </a>
                        <mat-autocomplete class="test-class" (optionSelected)="setWhere($event)" classlist="test-class"
                            #where="matAutocomplete">
                            <mat-option class="loading" *ngIf="loadingWhere">
                                <mat-spinner style="left: 50%; transform: translateX(-50%);"
                                    diameter="35"></mat-spinner>
                            </mat-option>
                            <ng-container
                                *ngIf="!loadingWhere && (list.rooms.length > 0 || list.publicAreas.length > 0)">
                                <mat-optgroup *ngIf="list.rooms && list.rooms.length > 0" [label]="'Rooms' | translate">
                                    <mat-option *ngFor="let item of list.rooms" [value]="item">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-optgroup>
                                <mat-optgroup *ngIf="list.publicAreas && list.publicAreas.length > 0"
                                    [label]="'Public area' | translate">
                                    <mat-option *ngFor="let item of list.publicAreas" [value]="item">
                                        {{ item.name }}
                                    </mat-option>
                                </mat-optgroup>
                            </ng-container>
                            <ng-container
                                *ngIf="!loadingWhere && list.rooms.length == 0 && list.publicAreas.length == 0 ">
                                <mat-option disabled="true">
                                    {{ 'There are no results for the search criteria used' | translate }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </div>
                    <div class="helperText" *ngIf="!data.whereName && dirty">
                        {{ 'You must select at least one option' | translate }}
                    </div>
                </div>
            </div>
            <div class="col s12 border__top__4line border-line "><br /></div>

            <div class="col s12 padding0" style="margin-top:-15px ;">
                <br />
                <div class="row">
                    <div class="col s12 m6 padding0">
                        <span class="color-base-two font-12 semiblod">
                            {{ 'Priority' | translate | uppercase }}
                        </span><br />
                        <span class="two color-base font-16 regular">
                            <a class="pointer col s12 margin-top-status padding0" [matMenuTriggerFor]="menu3"
                                style="margin-top:13px; border-bottom: 1px solid #9e9e9e;">
                                <span id="pending" class="semiblodBtn color-base position-relative-5">
                                    <i
                                        class="material-icons iconarrowprio left margin-rigth5 position-relative5 {{data.priorityColorHex}}">
                                        {{data.priorityIcon }}
                                    </i>
                                    {{ data.priorityName | translate }}
                                </span>
                                <i style="float: right;" class="material-icons color-base-two">
                                    arrow_drop_down
                                </i>
                            </a>
                            <mat-menu #menu3="matMenu" class="foundChangeStatusMenu col s12 l12 m12 margin-left-80"
                                style="margin-left: -80px !important;">
                                <a *ngFor="let priority of priorities" class="center" mat-menu-item
                                    (click)="updatePriority(priority)" style="margin-top: 1%;" class="clasitemselect">
                                    <div class="clasinteritem">
                                        <div class="diviconarr">
                                            <i *ngIf="priority.icon"
                                                class="material-icons iconarrowprio position-relative10 {{priority?.colorHex}}">
                                                {{ priority.icon }}
                                            </i>
                                            <span class="color-base relative-5">
                                                {{ priority.name | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            </mat-menu>
                        </span>
                    </div>
                    <div class="col s12 m6 padding0" style="padding-left: 15px !important">
                        <span class="color-base-two font-12 semiblod">
                            {{ 'Assignee' | translate | uppercase }}
                        </span><br />
                        <span class="two color-base font-16 regular">
                            <mat-form-field class="example-full-width col s12 padding0"
                                [ngClass]="{'asigne-free':!data.assigneeFirstName,'asigne-no-free':data.assigneeFirstName}">
                                <mat-chip-list #chipList class="margin-top10">
                                    <mat-chip *ngIf="data.assigneeFirstName" class="itememail" selectable="true"
                                        removable="true" (removed)="removeAssignee()" class="mat-chip-custom widht90">
                                        <span class="textitemsum cut-text">
                                            {{ data.assigneeFirstName + " " + data.assigneeLastName }}
                                        </span>
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>
                                <input *ngIf="!data.assigneeFirstName" class="classinput margin0" #eventsInput
                                    type="text" placeholder="{{ 'Select assignee' | translate }}" matInput
                                    [matAutocomplete]="auto" (keyup)="searcAssignee = $event.target.value;">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateAssignee($event)">
                                    <mat-option *ngFor="let user of users | filter: searcAssignee" [value]="user">
                                        <span class="textstyle" [ngClass]="{'textUppercase': user.disabled}">
                                            {{ user.name }}
                                        </span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="nopadding mygavete-buttons">
            <div class="right-align">
                <button (click)="cancel()" class="btn btn-falt-ant btn-small button-cancel" container="body"
                    placement="top">
                    {{ "Cancel" | translate }}
                </button>
                <button (click)="openDialogConfirm()" [disabled]="disabledButton"
                    class="btn btn-falt-ant btn-small button-save" container="body" placement="top">
                    {{ "Save" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div class="loadingdiv col s12 m12 xl12 center " *ngIf="loading">
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</div>