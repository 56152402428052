import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable()
export class EnabledIntegrationsService {
  constructor(private httpClient: HttpClient) {}
  hasAnyIntegrationEnabled(): Promise<boolean> {
    return this.httpClient
      .get(
        `${environment.backend.url}/${localStorage.getItem(
          "chosen_property"
        )}/integrations/available`
      )
      .toPromise()
      .then((result: Array<any>) =>
        result.some((integration) => integration.enabled)
      );
  }
}
