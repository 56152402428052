import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationComponent } from './component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';

import { ComponentModalConfirmedModule } from "../../components/modal-confirmed/module";

import { SideNavigationMenuModule } from "./menu/module";

@NgModule({
    declarations: [
        SideNavigationComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TooltipModule,
        RouterModule,
        SideNavigationMenuModule,
        MaterialModule,
        ComponentModalConfirmedModule
    ],
    exports: [
        SideNavigationComponent
    ],
    providers: []
})
export class SideNavigationModule { }
