import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


import { Auth0Service } from "../../auth0.service";
import { RequestCommentsService } from "./service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { EditorWebService } from "../../components/wysiwyg-web-summernote/service";
@Component({
  selector: "app-guesthub-req-details-comments",
  templateUrl: "template.html",
  styleUrls: ["styles.scss"],
})
export class ReqDetailsCommentsComponent implements OnInit, OnChanges {
  @Input() requestId: number;
  @Input() users: any = [];
  @Input() priorities: any = [];
  @Input() statuses: any = [];
  @Input() numComent: number;
  loadVisible = true;
  ticket: any;
  anonymousText: string;
  translate: any;
  dialog: any;
  auth: any;

  colorH: string;
  displayH: string;
  colorM = "white";
  displayM = "block";
  clasM = "HM";
  clasH: string;
  visible: boolean;

  history: any = [];
  comentNew: any = [];
  newComment: string = "";

  comments = [];
  commentsFilter: any = [];
  leerMas = false;
  indx: any;
  sizeCommet: any;
  requestType: any = "Messages";
  buscar_text: string = "";
  propertyValues: any = [];
  propertyValuesEs: any = [];
  item: any;
  template: any;
  visble: boolean;
  newComent: any;
  disabledButtonComments: boolean = true;
  constructor(
    private readonly commentsService: RequestCommentsService,
    translate: TranslateService,
    auth: Auth0Service,
    private _snackBar: MatSnackBar,
    private editorService: EditorWebService
  ) {
    this.auth = auth;
    this.translate = translate;
    //this.dialog = new DialogDx(this.translate);
  }
  configNew: any = {
    placeholder: "",
    height: 150,
    tabsize: 2,
    followingToolbar: false,
    codeviewFilter: false,
    codeviewIframeFilter: true,
    maximumImageFileSize: 500000,
    toolbar: [
      ["style", ["bold", "italic", "underline"]],

      ["insert", ["picture", "resizedDataImage"]],
    ],
    fontNames: [
      "Helvetica",
      "Arial",
      "Arial Black",
      "Comic Sans MS",
      "Courier New",
      "Roboto",
      "Times",
      "Open Sans",
    ],
    imageAttributes: {
      icon: '<i class="note-icon-pencil"/>',
      figureClass: "figureClass",
      figcaptionClass: "captionClass",
      captionText: "Caption Goes Here.",
      manageAspectRatio: true, // true = Lock the Image Width/Height, Default to true
    },
    lang: "en-Us",
    popover: {
      image: [
        ["imagesize", ["imageSize100", "imageSize50", "imageSize25"]],
        ["float", ["floatLeft", "floatRight", "floatCenter", "floatNone"]],
        ["remove", ["removeMedia"]],
        ["custom", ["imageAttributes"]],
      ],
    },
    codeCleaner: {
      stripTags: [
        "style",
        "script",
        "applet",
        "embed",
        "param",
        "noframes",
        "noscript",
        "font",
        "span",
        "iframe",
        "form",
        "input",
        "button",
        "select",
        "option",
        "colgroup",
        "col",
        "std",
      ],
      stripAttributes: ["rel", "class", "style"],
      wildCardAttributesPrefix: ["data-"],
      msgStyle: "position:absolute;top:0;left:0;right:0",
      msgShow: false, //true of false
    },
  };
  buscar(str: string = "") {
    if (str.length > 0) {
      this.commentsFilter = this.comments.filter(
        (c) =>
          c.author.firstName.toLowerCase().search(str.toLowerCase()) > -1 ||
          c.author.lastName.toLowerCase().search(str.toLowerCase()) > -1 ||
          c.author.email.toLowerCase().search(str.toLowerCase()) > -1 ||
          c.body.toLowerCase().search(str.toLowerCase()) > -1
      );
    } else {
      this.commentsFilter = this.comments;
    }
  }

  getLanguageTextSimple(message: string): Promise<any> {
    return this.translate
      .get(message)
      .toPromise()
      .then((resp) => {
        return resp;
      })
      .catch(console.error);
  }

  getComents(newRequestId) {
    Promise.all([
      this.commentsService.getTicketComments(newRequestId),
      this.commentsService.getTicketHistory(newRequestId),
    ]).then(([comments, history]) => {

      this.comments = this.editorService.parseComments(comments).reverse();
      this.commentsFilter = this.comments
      this.parseHistory(history);
      this.visible = true;
    });
  }

  letras(text) {
    text = text.replace(/[A-Za-z]+/g, function (match) {
      return match.trim()[0];
    });
    text.replace(/\s/g, "");
    return text.replace("-", "");
  }

  changTabHM(tab) {
    this.requestType = tab;
    if (tab === "Messages") {
      this.colorM = "white";
      this.displayM = "block";
      this.clasM = "HM";
      this.clasH = "";
      this.colorH = "";
      this.displayH = "";
    } else if (tab === "History") {
      this.colorH = "white";
      this.displayH = "block";
      this.clasH = "HM";
      this.clasM = "";
      this.colorM = "";
      this.displayM = "";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  ngOnInit() {
    this.getLanguageTextSimple("Anonymous").then((resp) => {
      this.anonymousText = resp;
    });
    this.getComents(this.requestId);
  }

  Leer(indx) {
    if (this.leerMas) {
      this.leerMas = false;
    } else {
      this.leerMas = true;
      this.indx = indx;
    }
  }

  editContenHtmlEditor(e) {
    this.newComent = e.toString().trim();
    this.disabledButtonComments = e.toString().trim().length === 0 ? true : false;

  }

  createComment() {
    this.visble = true;
    this.disabledButtonComments = false;

    this.commentsService
      .create(this.newComent, this.requestId)
      .then(() => this.refreshEditor())
      .then(() => {
        this.newComment = "";
        this.template = null;
        this.disabledButtonComments = true;
      })
      .then(() => this.getComents(this.requestId))
      .catch(() => this.refreshEditor());
  }
  refreshEditor() {
    this.openSnackBar(
      this.translate.instant("Message has been successfully created"),
      "✓"
    );
    this.getComents(this.requestId);
    this.newComment = "";
    this.template = "";
    this.visble = false;
  }
  sortByDate = (obj1, obj2) => {
    const date1 = this.isoDateToNative(obj1.creationDate);
    const date2 = this.isoDateToNative(obj2.creationDate);
    return date2 > date1 ? 1 : -1;
  };
  sortByDateInverse = (obj1, obj2) => {
    const date1 = this.isoDateToNative(obj1.id);
    const date2 = this.isoDateToNative(obj2.id);
    return date2 < date1 ? 1 : -1;
  };
  isoDateToNative(string) {
    const regexp =
      "([0-9]{4})(-([0-9]{2})(-([0-9]{2})" +
      "(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(.([0-9]+))?)?" +
      "(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?";
    const d = string.match(new RegExp(regexp));

    let offset = 0;
    const date = new Date(d[1], 0, 1);

    if (d[3]) {
      date.setMonth(d[3] - 1);
    }
    if (d[5]) {
      date.setDate(d[5]);
    }
    if (d[7]) {
      date.setHours(d[7]);
    }
    if (d[8]) {
      date.setMinutes(d[8]);
    }
    if (d[10]) {
      date.setSeconds(d[10]);
    }
    if (d[12]) {
      date.setMilliseconds(Number("0." + d[12]) * 1000);
    }
    if (d[14]) {
      offset = Number(d[16]) * 60 + Number(d[17]);
      offset *= d[15] === "-" ? 1 : -1;
    }
    offset -= date.getTimezoneOffset();
    const time = Number(date) + offset * 60 * 1000;
    const resultDate = new Date();
    resultDate.setTime(Number(time));
    return resultDate;
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
  private parseHistory(history: any) {
    this.history = history.reverse();
    this.history = this.history.map((h) => {
      switch (h.type) {
        case "requests-status":
          h.from = this.statuses.find((s) => s.id == h.from);
          h.to = this.statuses.find((s) => s.id == h.to);
          break;
        case "requests-priority":
          h.from = this.priorities.find((s) => s.id == h.from);
          h.to = this.priorities.find((s) => s.id == h.to);
          break;
        case "requests-assignee":
          h.from = this.users.find((s) => s.id == h.from);
          h.to = this.users.find((s) => s.id == h.to);
          break;
        case "lost-status":
          const fromlost = this.statuses.find((s) => s.id == h.from);
          const tolost = this.statuses.find((s) => s.id == h.to);

          h.from = fromlost;
          h.to = tolost;
          break;
        case "found-status":
          const fromfound = this.statuses.find((s) => s.id == h.from);
          const tofound = this.statuses.find((s) => s.id == h.to);

          h.from = fromfound;
          h.to = tofound;
          break;
        case "packages-status":
          const from = this.statuses.find((s) => s.id == h.from);
          const to = this.statuses.find((s) => s.id == h.to);

          h.from = from;
          h.to = to;
          break;
      }

      h.author
        ? (h.author.name = h.author.firstName
          ? h.author.firstName
          : "" + " " + h.author.lastName
            ? h.author.lastName
            : "")
        : Object.assign(h, { autor: { name: "" } });

      return h;
    });
  }




}
