    <div  class="col s12 loadding__component">
      <div *ngIf="type == 'default'" class="preloader-wrapper big active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
      <div *ngIf="type == 'linkPhoto'" class="preloader-wrapper big active">
        <div class="spinner-layer spinner-layer--link">
          <div class="circle-clipper  left">
            <div class="circle circle--link"></div>
          </div>
          <div class="gap-patch">
            <div class="circle circle--link"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle circle--link"></div>
          </div>
        </div>
      </div>
      <div *ngIf="type == 'withtext'" class="loadding__component__text col s12">
        <span style="color:var(--gh-color-dynamic-1) !important;">{{ 'Loading...' | translate }}</span>
      </div>
    </div>
