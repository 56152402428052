import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
 
@Pipe({
  name: 'localizedDate',
  pure: false
})
export class DatePipeComponent implements OnInit, OnDestroy {
  today;
  @Input() format: string;
  private intervalHandler: any;

  constructor(
    private translateService: TranslateService) { }
  transform(value: any, pattern: string = 'fullDate'): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern);
  }
  ngOnInit() {



    this.today = Date.now();
    this.intervalHandler = setInterval(() => {
      this.today = Date.now();
    }, 1000);

  }

  ngOnDestroy() {
    if (this.intervalHandler !== undefined)
      clearInterval(this.intervalHandler);
    this.intervalHandler = undefined;
  }
}