import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../../services/url-builder.service";
import { Auth0Service } from "../../../auth0.service";

@Injectable()
export class UserService {
  private users: any;
  /**
   * RolesService constructor
   * @param http Http variable
   * @param urlbuilder UrlBuilderService instance, used to concat strings (urls)
   */
  constructor(private http: HttpClient, private urlbuilder: UrlBuilderService, private auth: Auth0Service) {
    this.users = this.http.get<any>(this.urlbuilder.getAllUsers()).toPromise();
  }

  /**
   * Method made to handle errors if any
   * @param error Error description
   */
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getAllUsers() {
    return this.http
      .get<any>(this.urlbuilder.getAllUsers())
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  getAllUserstransport() {
    return this.http
      .get<any>(this.urlbuilder.getAllUsers())
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  getAllUsersRequest(id, tiket) {
    return this.http
      .patch<any>(this.urlbuilder.getAllUsersRequest(id), tiket)
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }

  blockUnblockUserById(id) {
    return this.http
      .put<any>(`${this.urlbuilder.appApi
        .concat("/")
        .concat(
          this.auth.getChosenProperty.toString()
        )
        .concat("/people-center/user/")
        .concat(id)
        .concat("/block-unblock")}`, {})
      .toPromise();
  }

  updateUser(user, id) {
    return this.http.put<any>(`${this.urlbuilder.appApi
      .concat("/people-center/user/")
      .concat(id)}`, user).toPromise();
  }
  updateUserPassword(data, id) {
    return this.http
      .put<any>(this.urlbuilder.updateUserPassword(id), data)
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }
  updateUserPasswordCurrent(data) {
    return this.http
      .put<any>(this.urlbuilder.updateUserPasswordCurrent(1), data)
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }

  getAllUsersByDepartmentId(id) {
    return this.http
      .get<any>(this.urlbuilder.getAllUsersByDepartmentId(id))
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }
  getRequestDepartUser(id, dep) {
    return this.http
      .get<any>(this.urlbuilder.get_most_requestedUserId(id, dep))
      .toPromise()
      .then(requests =>
        requests.map(request => {
          let execution: any = 0;
          let preparation: any = 0;
          let timeTotal: any = 0;
          if (
            request.process.time &&
            request.process.time.execution &&
            request.process.time.followUp
          )
            execution = request.process.time.execution.split(" ");
          preparation = request.process.time.followUp.split(" ");
          let executionh = parseInt(execution[0]);
          let executionm = parseInt(execution[2]);
          let preparationh = parseInt(preparation[0]);
          let preparationm = parseInt(preparation[2]);
          execution =
            parseInt(execution[0]) + "h" + " " + parseInt(execution[2]) + "m";
          preparation =
            parseInt(preparation[0]) +
            "h" +
            " " +
            parseInt(preparation[2]) +
            "m";
          timeTotal =
            executionh +
            preparationh +
            "h" +
            " " +
            (executionm + preparationm) +
            "m";

          return {
            id: request.id,
            status: {
              id: request.status.id,
              name: request.status.name,
              colorHex: request.status.color
            },
            priority: {
              id: request.priority.id,
              name: request.priority.name,
              severity: request.priority.severity,
              colorHex: request.priority.color
            },
            department: {
              id: request.department ? request.department.id : null,
              name: request.department ? request.department.name : null
            },
            processCategoryName: request.process.category,
            createBy: request.location.guestName,
            roomNumber: request.location.roomNumber,
            processId: request.process.id,
            processName: request.process.subCategory,
            timeExecutionHours: execution,
            followUpTimeHours: preparation,
            timeTotal: timeTotal
          };
        })
      )
      .catch(this.handleError);
  }
  getRequestDepartUserAll(dep) {
    return this.http
      .get<any>(this.urlbuilder.get_most_requesteddepId(dep))
      .toPromise()
      .then(requests =>
        requests.map(request => {
          let execution: any = 0;
          let preparation: any = 0;
          let timeTotal: any = 0;
          if (
            request.process.time &&
            request.process.time.execution &&
            request.process.time.followUp
          )
            execution = request.process.time.execution.split(" ");
          preparation = request.process.time.followUp.split(" ");
          let executionh = parseInt(execution[0]);
          let executionm = parseInt(execution[2]);
          let preparationh = parseInt(preparation[0]);
          let preparationm = parseInt(preparation[2]);
          execution =
            parseInt(execution[0]) + "h" + " " + parseInt(execution[2]) + "m";
          preparation =
            parseInt(preparation[0]) +
            "h" +
            " " +
            parseInt(preparation[2]) +
            "m";
          timeTotal =
            executionh +
            preparationh +
            "h" +
            " " +
            (executionm + preparationm) +
            "m";
          return {
            id: request.id,
            status: {
              id: request.status.id,
              name: request.status.name,
              colorHex: request.status.color
            },
            priority: {
              id: request.priority.id,
              name: request.priority.name,
              severity: request.priority.severity,
              colorHex: request.priority.color
            },
            department: {
              id: request.department ? request.department.id : null,
              name: request.department ? request.department.name : null
            },
            processCategoryName: request.process.category,
            createBy: request.location.guestName,
            roomNumber: request.location.roomNumber,
            processId: request.process.id,
            processName: request.process.subCategory,
            timeExecutionHours: execution,
            followUpTimeHours: preparation,
            timeTotal: timeTotal
          };
        })
      )
      .catch(this.handleError);
  }
  deleteUserId(id) {
    return this.http
      .delete<any>(this.urlbuilder.removeUserByIdTenand(id))
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }

  getAllLanguagesList() {
    return this.http
      .get<any>(this.urlbuilder.getAllLanguages())
      .toPromise()
      .catch(this.handleError);
  }

  getUser(id) {
    return this.http
      .get<any>(this.urlbuilder.getUser(id))
      .toPromise()
      .catch(this.handleError);
  }

  updateUserPasswordCurrentid(id, data) {
    return this.http
      .put<any>(this.urlbuilder.updateUserPassword(id), data)
      .toPromise()
      .catch(this.handleError);
  }
}
