import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


import { UrlBuilderService } from "./url-builder.service";

@Injectable()
export class GetTicketsService {
  priorities: Promise<any>;
  statuses: any;
  /**
   * GetTicketsService constructor
   * @param http Http variable
   * @param urlbuilder UrlBuilderService instance, used to concat strings (urls)
   */
  constructor(private http: HttpClient, private urlbuilder: UrlBuilderService) {
    this.priorities = this.http
      .get<any>(this.urlbuilder.getAllTicketPriorities())
      .toPromise();

    this.statuses = this.http
      .get<any>(this.urlbuilder.getAllTicketStatuses())
      .toPromise();
  }

  /**
   * Method made to handle errors if any
   * @param error Error description
   */
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getAllTicketsByRequestId(id: number): Promise<any> {
    return this.http
      .get<any>(this.urlbuilder.requests.get(id))
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }

  getAllTicketStatuses(): Promise<any> {
    return this.statuses;
  }

  getAllTicketPriorities(): Promise<any> {
    return this.priorities;
  }

  getAllRules(): Promise<any> {
    return Promise.resolve([]);
  }

  updateTicketById(ticketId: number, userId): Promise<any> {
    //
    //alert(this.urlbuilder.categoryChangeEnabled(id,enable));
    return this.http
      .put<any>(this.urlbuilder.updateTicketById(ticketId), userId)
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }
  /*

  setStatusOnTicket(ticketId: number, statusId: number): Promise<any> {
    //
    //alert(this.urlbuilder.categoryChangeEnabled(id,enable));
    return this.http.patch<any>(this.urlbuilder.setStatusOnTicket(ticketId, statusId), {})
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }

  setPriorityOnTicket(ticketId: number, priorityId: number): Promise<any> {
    //
    //alert(this.urlbuilder.categoryChangeEnabled(id,enable));
    return this.http.patch<any>(this.urlbuilder.setPriorityOnTicket(ticketId, priorityId), {})
      .toPromise()
      .then(resp => resp)
      .catch(this.handleError);
  }
*/
  setNameAndStateRule(ruleId: number, detalles: Object): Promise<any> {
    return Promise.resolve({});
  }

  deleteRule(ruleId: number): Promise<any> {
    return Promise.resolve({});
  }
}
