<div class="">
  <div class="" style="height: auto">
    <div class="" style="height: auto; overflow-y: auto; overflow-x: hidden">
      <ul class="tabs" style="background: transparent">
        <li class="tab col s6 l6 m6 xl6" style="background: transparent"
          [ngClass]="{ 'activeTab': requestType == 'Messages' }" (click)="changTabHM('Messages')">
          <a class="black-text">{{ "Comments" | translate }}</a>
        </li>
        <li class="tab col s6 l6 m6 xl6" style="background: transparent"
          [ngClass]="{ 'activeTab': requestType == 'History' }" (click)="changTabHM('History')">
          <a class="black-text">{{ "History" | translate }}</a>
        </li>
      </ul>

      <div class="tab-content col s12 l12 m12 card margin0 padding-bottom15 z-depth-2">
        <br />

        <div id="tabHistory" class="tab-pane" [style.display]="displayM" *ngIf="displayM === 'block'">
          <div class="panel-body contdivpad" style="overflow: auto">
            <span *ngIf="comentNew && comentNew.name && comentNew.comment">
              <div class="col s4 l3 m3">
                <br />
                <span class="btn btn-default btn-floating btn-small col s12" style="background: #d8d8d8 !important">
                  {{ comentNew.name }}
                </span>
              </div>
              <span class="col s8 m9 l9" style="float: right">
                <h4>
                  <strong>{{ comentNew.guestName }} </strong>
                </h4>
              </span>
              <span class="col s12 justify-text" style="overflow-y: auto">
                <span style="white-space: pre-line">
                  {{ comentNew.comment }}
                </span>
                <hr />
              </span>
            </span>

            <div *ngIf="displayM && visible">
              <div class="input-field col s12 l4aho padding0">
                <i class="material-icons prefix">search</i>
                <input id="searchComments" type="text" class="validate" [(ngModel)]="buscar_text"
                  (keyup)="buscar($event.target.value)" />
                <label for="searchComments" [ngClass]="{ 'active': buscar_text.length > 0 }">
                  {{ "Search" | translate }}
                </label>
              </div>

              <div class="col s12 nodpad">
                <div class="input-field col s12 padding0" *ngIf="!visble">
                  <app-web-editor-component [en]="propertyValues" [es]="propertyValuesEs" class="editor-html"
                    (changeEditor)="editContenHtmlEditor($event)" [configNew]="configNew" [Id]="'4_uno'"
                    [html]="template" [routeSave]="'requests'">
                  </app-web-editor-component>
                </div>
                <div class="contcomo">
                  <div class="col s12 l12 m2 padding0">
                    <button type="submit" class="btnflo btn btn-falt-ant btn-small" (click)="createComment()"
                      style="border-radius: 0px" [ngClass]="{ 'disabled': disabledButtonComments || !newComent }">
                      {{ "Comment" | translate }}
                    </button>
                  </div>
                  <div class="col s12 l12 m12">
                    <br /><br /><br />
                  </div>


                </div>
              </div>
            </div>

            <span *ngFor="let comment of commentsFilter; let i = index" class="col s12 nodpad" [ngClass]="{
                'myauthor': auth.userProfile.id === comment.author?.id
              }">
              <div class="padding0 col s12 m12 l12 xl12">
                <span class="btn btn-default btn-floating btncomments btn-small btn-initials"
                  style="background: #d8d8d8 !important">
                  {{ comment?.author?.name }}
                </span>
                <span class="font-name font14">
                  {{ comment?.author?.firstName ? comment?.author?.firstName + "
                  " + comment?.author?.lastName : anonymousText }}
                </span>

                <span class="font-date">{{ comment?.creationDate | date: "MMM" | translate }}
                </span>
                <span class="font-date">
                  {{ comment?.creationDate | date: "dd yyyy h:mm:ss a" }}h</span>
              </div>

              <div class="col s12 comment" style="overflow-y: auto">
                <div [innerHtml]="comment.body"></div>
              </div>
              <div class="col s12 xl12 m12 l12">
                <br>
              </div>
            </span>
          </div>
        </div>
        <div id="tabHistory" class="tab-pane" [style.display]="displayH" *ngIf="displayH === 'block'">
          <div class="panel-body contdivpad" style="overflow: auto">
            <span *ngFor="let comment of history; let i = index">
              <div class="col s12">
                <div class="padding0 col s12 m12 l12 xl12 margin-bottom10">
                  <span class="btn btn-default btn-floating btncomments btn-small btn-initials"
                    style="background: #d8d8d8 !important">
                    {{ comment?.author?.name }}
                  </span>
                  <span class="font-name font14">
                    {{ comment?.author?.firstName ? comment?.author?.firstName +
                    " " + comment?.author?.lastName : anonymousText }}
                  </span>

                  <span class="font-date">{{ comment?.creationDate | date: "MMM" | translate }}
                  </span>
                  <span class="font-date">
                    {{ comment?.creationDate | date: "dd yyyy h:mm:ss a"
                    }}</span>

                </div>

                <div class="col s12 comment">
                  <span>
                    <span>
                      <div class="panel-body justify-text">
                        <div *ngIf="comment?.type == 'lost-create'">
                          {{ "You have created this request" | translate }}

                        </div>
                        <div *ngIf="comment?.type == 'packages-create'">
                          {{ "You have created this request" | translate }}

                        </div>
                        <div *ngIf="comment?.type == 'found-create'">
                          {{ "You have created this request" | translate }}

                        </div>
                        <div *ngIf="comment?.type == 'requests-status'">
                          {{ "Changed status from" | translate }}
                          <b>{{ comment.from?.name | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to?.name | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'requests-priority'">
                          {{ "Changed priority from" | translate }}
                          <b>{{ comment.from?.name | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to?.name | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'requests-assignee'">
                          {{ "Changed assignee" | translate }}
                          <b *ngIf="comment.from"> {{ "from" | translate }} {{ comment.from?.firstName }} {{
                            comment.from?.lastName }}</b>
                          <b *ngIf="!comment.from"> {{ "from" | translate }} {{ anonymousText }}</b>
                          {{ "to" | translate }}
                          <b *ngIf="comment.to">{{ comment.to?.firstName }} {{ comment.to?.lastName
                            }}</b>
                          <b *ngIf="!comment.to">{{ anonymousText }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'requests-process-name'">
                          {{ "Changed Subcategory name from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-enabled'">
                          {{ "Changed from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-description'">
                          {{ "Changed description from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-last-place-seen'">
                          {{ "Changed last place seen from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-guest-email'">
                          {{ "Changed guest email from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-guest-phone'">
                          {{ "Changed guest phone from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-status'">
                          {{ "Changed status from" | translate }}
                          <b>{{ comment.from?.name | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to?.name | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-category'">
                          {{ "Changed category from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-brand'">
                          {{ "Changed brand from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-model'">
                          {{ "Changed model from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'lost-last-time-seen'">
                          {{ "Changed last time seen from" | translate }}
                          <b>{{ comment.from | date: "MMMM" | translate }}</b>
                          <b>{{ comment.from | date: "dd yyyy h:mm:ss a" }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | date: "MMMM" | translate }}</b>
                          <b>{{ comment.to | date: "dd yyyy h:mm:ss a" }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-enabled'">
                          {{ "Changed from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-description'">
                          {{ "Changed description from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-status'">
                          {{ "Changed status from" | translate }}
                          <b>{{ comment.from?.name | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to?.name | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-category'">
                          {{ "Changed category from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-brand'">
                          {{ "Changed brand from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-model'">
                          {{ "Changed model from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'found-owner-was-found'">
                          {{ "Changed owner was found from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'packages-enabled'">
                          {{ "Changed from" | translate }}
                          <b>{{ comment.from | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'packages-description'">
                          {{ "Changed description from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'packages-status'">
                          {{ "Changed status from" | translate }}
                          <b>{{ comment.from?.name | translate }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to?.name | translate }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'packages-who-claimed'">
                          {{ "Changed who claimed from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'packages-objectLocation'">
                          {{ "Changed package location from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>

                        <div *ngIf="comment?.type == 'package-signature'">
                          {{ "Changed signature from" | translate }}
                          <b>{{ comment.from }}</b>
                          {{ "to" | translate }}
                          <b>{{ comment.to }}</b>
                        </div>
                      </div>
                    </span>
                  </span>
                  <div class="col s12 xl12 m12 l12">
                    <br>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="col s12 l12 m12">
        <br />
      </div>
    </div>

    <br />
    <br />
  </div>
</div>