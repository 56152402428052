import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';





import { UrlBuilderService } from './url-builder.service'

@Injectable()
export class PostResetUserPasswordService {
  /**
   * PostResetUserPasswordService constructor
   * @param http Http variable
   * @param urlbuilder UrlBuilderService instance, used to concat strings (urls)
   */
  constructor(private http: HttpClient,
    private urlbuilder: UrlBuilderService) { }

  /**
   * Method made to handle errors if any
   * @param error Error description
   */
  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }

  /**
   * Method made to convert data into json objects
   * @param res Data to convert
   */
  private extractData(res: any) {
    let body = res;
    return body.data || {};
  }

  /**
   * Method creates request sended to backend. <br>
   * Contains one element as described in backend API.
   */

  resetUserPassword(email: string): Promise<any> {

    return this.http.post<any>(this.urlbuilder.resetUserPassword(), { email: email })
      .toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
}
