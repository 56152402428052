import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlBuilderService } from "../../services/url-builder.service";
import { Auth0Service } from "../../auth0.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class ModalChangeImagenService {
  constructor(
    private http: HttpClient,
    private urlbuilder: UrlBuilderService,
    private auth: Auth0Service
  ) {}
  activeEditImg$ = new EventEmitter<boolean>();
  imageEditImg$ = new EventEmitter<any>();

  uploadImgs(data) {
    data.append("userId", this.auth.userProfile.id);
    return this.http.post<any>(
      `${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(
        environment.BACKENDFILES.DOMAIN
      )}/images/${this.auth.getChosenProperty}`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
}
