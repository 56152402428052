import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MyProfileService } from "../service";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-my-profile-change-password',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @Output() output = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();
  @ViewChild("currentPassword") currentPassword: ElementRef;
  @ViewChild("newPassword") newPassword: ElementRef;
  @ViewChild("repeatPassword") repeatPassword: ElementRef;
  disabledButton: boolean = true;

  data: any = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: ''
  };
  errorRepeatPasswordInvalid: boolean = false;

  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    public router: Router,
    private service: MyProfileService
  ) {
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {

  }

  _autoValidate() {
    this.disabledButton = this.data.currentPassword.toString().trim().length < 8
      || this.data.newPassword.toString().trim().length < 8
      || this.data.repeatPassword.toString().trim().length < 8
      || this.data.newPassword.toString() != this.data.repeatPassword.toString();
    if (this.data.repeatPassword.toString().trim().length > 0
      && this.data.newPassword.toString() != this.data.repeatPassword.toString()) {
      this.errorRepeatPasswordInvalid = true;
    } else {
      this.errorRepeatPasswordInvalid = false;
    }
  }

  back() {
    this.goBack.emit(null);
  }

  save() {
    this.disabledButton = true;
    this.service.updatePassword(this.data)
      .then(response => {
        this.output.emit(response);
      }).catch(err => {
        console.error(err);
        this.disabledButton = false;
      });
  }

  changeType(type) {
    this[type].nativeElement.type = this[type].nativeElement.type == 'password' ? 'text' : 'password';
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
